import { DangerousTextBody, MultipleChoiceAlternatives } from "components";
import { IMultipleChoiceQuestion, IQuestionAnswer, IQuestionSolution } from "models/quiz";

interface SingleMultipleChoiceProps {
  question: IMultipleChoiceQuestion;
  solution: IQuestionSolution;
  answers: Record<string, IQuestionAnswer>;
  addAnswer: (q: IMultipleChoiceQuestion, id: string) => void;
}

export const SingleMultipleChoice = (props: SingleMultipleChoiceProps) => {
  return (
    <>
      <DangerousTextBody html={props.question.questionHtml} />
      <MultipleChoiceAlternatives
        correctAlternatives={props.solution?.correctAlternatives}
        alternatives={props.question.alternatives}
        value={props.answers[props.question.id]?.alternativeId}
        setValue={(id) => {
          props.addAnswer(props.question, id);
        }}
      />
    </>
  );
};
