const debounce = (callback: () => void, ms = 250, minWait = 500): (() => void) => {
  let timer: NodeJS.Timeout;
  let lastExecution = 0;
  const execute = () => {
    callback();
    lastExecution = Date.now();
  };
  return () => {
    clearTimeout(timer);
    if (minWait > 0 && Date.now() - lastExecution > minWait) {
      execute();
    }
    timer = setTimeout(execute, ms);
  };
};

export default debounce;
