import { ForwardedRef, forwardRef, FunctionComponent, RefObject, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { PrivateApi } from "api/service";
import {
  DangerousTextBody,
  MultipleChoice,
  PreNextLink,
  SubchapterBottomNavigation,
  Tabs,
  TextQuestions,
} from "components";
import { useHeaderSubtitle } from "hooks/header.hooks";
import { useSubchapterFetch } from "hooks/course.hooks";
import { AppAnchors } from "models/paths";
import { inView, scrollToTop, useScrollEvent } from "utils/scroll.util";
import debounce from "utils/debounce";
import useTexts from "config/texts";

interface SubchapterHeaderProps {
  heading: string;
  label?: string;
  small?: boolean;
}

const SubchapterHeader = forwardRef((props: SubchapterHeaderProps, ref: ForwardedRef<HTMLHeadingElement>) => {
  const { heading, label, small = false } = props;
  if (heading == null) {
    return null;
  }
  return (
    <h2 ref={ref} className={classNames("msr-chapter-header", { small })}>
      {label && <span style={{ fontWeight: 400, fontSize: "1.2rem" }}>{label}</span>}
      <br />
      {heading}
    </h2>
  );
});

const useSubchapterHeader = (ref: RefObject<HTMLHeadingElement>, heading: string, label?: string) => {
  const setHeader = useHeaderSubtitle();

  const [showHeader, setVisibility] = useState(false);
  useScrollEvent(debounce(() => setVisibility(!inView(ref?.current)), 250, 500));

  useEffect(
    () => setHeader(showHeader ? <SubchapterHeader heading={heading} label={label} small /> : null),
    [setHeader, showHeader, heading, label]
  );
};

interface SubchapterProps {
  courseId: string;
  subchapterId: string;
  chapterNumber: number;
  chapterTitle: string;
  subchapterNumber: number;
  subchapterTitle: string;
  previousHref?: string;
  nextHref?: string;
}

const Subchapter: FunctionComponent<SubchapterProps> = ({
  courseId,
  subchapterId,
  chapterNumber,
  chapterTitle,
  subchapterTitle,
  subchapterNumber,
  previousHref,
  nextHref,
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const texts = useTexts();
  const { subchapter, loading, updateMultipleChoice } = useSubchapterFetch(courseId, subchapterId);

  const headerHeading = `${chapterNumber}.${subchapterNumber} ${subchapterTitle}`;
  const headerLabel = `${chapterNumber} ${chapterTitle}`;
  useSubchapterHeader(ref, headerHeading, headerLabel);
  const [currentTab, setCurrentTab] = useState<string>("tab-1");

  useEffect(() => {
    setCurrentTab("tab-1");
  }, [subchapterId]);

  if (subchapter == null || loading) {
    scrollToTop();
    return <p>{texts.loading}</p>;
  }

  const { questions = [], multipleChoice = [], textHtml } = subchapter;
  const hasMC = multipleChoice.length > 0;
  const hasQ = questions.length > 0;

  const tabs = [
    {
      id: "tab-1",
      title: texts.subchapter.textLabel,
      body: <DangerousTextBody id={AppAnchors.Text} html={textHtml} />,
      hide: textHtml === "",
    },
    {
      id: "tab-2",
      title: texts.subchapter.multipleChoiceLabel,
      body: (
        <MultipleChoice
          courseId={courseId}
          documentId={subchapterId}
          questions={multipleChoice}
          onRetry={() => PrivateApi.fetchSubchapterMultipleChoice(courseId, subchapterId).then(updateMultipleChoice)}
        />
      ),
      hide: !hasMC,
    },
    {
      id: "tab-3",
      title: texts.subchapter.questionLabel,
      body: <TextQuestions questions={questions} />,
      hide: !hasQ,
    },
  ];

  return (
    <>
      <PreNextLink previousHref={previousHref} nextHref={nextHref} />
      <SubchapterHeader ref={ref} label={headerLabel} heading={headerHeading} />
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabs} />
      <SubchapterBottomNavigation
        previousHref={previousHref}
        nextHref={nextHref}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabs={tabs}
      />
    </>
  );
};

export default Subchapter;
