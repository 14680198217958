import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { Accordion } from "components";
import uniqueId from "utils/unique.util";
import classNames from "classnames";

interface NavItemBodyProps {
  prefix?: string;
  title: string;
  subtitle?: string;
}

const NavItemBody = ({ prefix, title, subtitle }: NavItemBodyProps) => (
  <>
    {prefix && <p className="item-title item-title-prefix">{prefix}&nbsp;</p>}
    <span>
      <p className="item-title">{title}</p>
      {subtitle && <p className="item-subtitle">{subtitle}</p>}
    </span>
  </>
);

interface NavItemProps {
  prefix?: string;
  title: string;
  subtitle?: string;
  active?: boolean;
  href?: string;
}

const NavItem = ({ prefix, title, subtitle, active, href }: NavItemProps) =>
  href == null ? (
    <span className="msr-sidebar-navigation-item">
      <NavItemBody prefix={prefix} title={title} subtitle={subtitle} />
    </span>
  ) : (
    <Link className={classNames("msr-sidebar-navigation-item", { open: active })} to={href}>
      <NavItemBody prefix={prefix} title={title} subtitle={subtitle} />
    </Link>
  );

interface NavAccordionProps {
  title: string;
  active?: boolean;
  subitems?: {
    prefix?: string;
    title: string;
    href: string;
    active?: boolean;
  }[];
}

const NavAccordion = ({ title, active, subitems }: NavAccordionProps) => (
  <Accordion title={title} open={active}>
    {subitems?.map((item) => (
      <NavItem
        key={uniqueId("navigation-item")}
        prefix={item.prefix}
        title={item.title}
        href={item.href}
        active={item.active}
      />
    ))}
  </Accordion>
);

export type NavigationItem = NavItemProps & NavAccordionProps;

interface SidebarNavigationProps {
  title: string;
  items: NavigationItem[];
  className?: string;
}

export const SidebarNavigation: FunctionComponent<SidebarNavigationProps> = ({
  title,
  items,
  className,
}): JSX.Element =>
  (items?.length ?? 0) > 0 ? (
    <section className={classNames("msr-sidebar-navigation", className)}>
      <h3 className="msr-sidebar-navigation-header">{title}</h3>
      {items.map((item: NavigationItem) =>
        (item.subitems?.length || 0) > 0 ? (
          <NavAccordion
            key={uniqueId("navigation-item-group")}
            title={item.title}
            active={item.active}
            subitems={item.subitems}
          />
        ) : (
          <NavItem
            key={uniqueId("navigation-item")}
            prefix={item.prefix}
            title={item.title}
            subtitle={item.subtitle}
            active={item.active}
            href={item.href}
          />
        )
      )}
    </section>
  ) : (
    <></>
  );

export default SidebarNavigation;
