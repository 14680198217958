export interface GaConfig {
  targetId: string;
  appName: string;
  allowTracking: boolean;
  userId?: string;
  debug?: boolean;
}

export enum PurchaseType {
  PURCHASE,
  TRIAL,
}

interface ViewParams {
  id: string;
  name: string;
}

interface PurchaseParams extends ViewParams {
  transaction_id: string;
  type: PurchaseType;
  duration: number;
  price: number;
}

class GoogleAnalytics {
  private readonly _userId: string;

  constructor(config: GaConfig) {
    if (config.userId != null && config.allowTracking) {
      this._userId = config.userId;
    } else {
      this._userId = "anonymous-user";
    }

    gtag("config", config.targetId, {
      app_name: config.appName,
      user_id: this._userId,
      debug_mode: config.debug ?? false,
    });

    const rule = config.allowTracking ? "granted" : "denied";
    gtag("consent", "update", {
      ad_storage: rule,
      analytics_storage: rule,
    });
  }

  public get userId() {
    return this._userId;
  }

  public view(params: ViewParams) {
    gtag("event", "view_item", { items: [{ item_id: params.id, item_name: params.name }] });
  }

  public purchase(params: PurchaseParams) {
    const name =
      params.type === PurchaseType.TRIAL
        ? `${params.name} - ${params.duration} days trial`
        : `${params.name} - ${params.duration} months`;

    gtag("event", "purchase", {
      transaction_id: params.transaction_id,
      affiliation: "MyStudyRoom",
      value: params.price,
      currency: "NOK",
      items: [
        {
          item_id: params.id,
          item_name: name,
          price: params.price,
          currency: "NOK",
        },
      ],
    });
  }
}

export default GoogleAnalytics;
