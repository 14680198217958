import React, { useCallback, useState } from "react";
import classNames from "classnames";

import { sanitize } from "utils/sanitize.util";
import renderMathInElement from "katex/dist/contrib/auto-render";
import { Modal } from "components";
import { useDictionaryWord } from "hooks/dictionary.hooks";
import useTexts from "config/texts";

import "katex/dist/katex.min.css?raw";

interface DictionaryModalProps {
  link?: HTMLLinkElement;
  open: boolean;
  close: () => void;
}

const DictionaryModal = ({ link, open, close }: DictionaryModalProps) => {
  const texts = useTexts();
  const dictionaryWord = useDictionaryWord(link?.id);

  if (dictionaryWord == null) {
    return <></>;
  }

  return (
    <Modal open={open} close={close}>
      <h3>{dictionaryWord.word}</h3>
      <DangerousTextBody html={dictionaryWord.descriptionHtml} />
      <a href={link?.href}>{texts.dictionary.continueToDictionary}</a>
    </Modal>
  );
};

export interface DangerousHTMLProps {
  html: string;
  id?: string;
  className?: string;
}

export const DangerousTextBody = ({ html, id, className }: DangerousHTMLProps) => {
  const [open, setOpen] = useState(false);
  const [linkClicked, setLinkClicked] = useState<HTMLLinkElement>();
  const onRef = useCallback(
    (el) => {
      if (html != null && el != null) {
        renderMathInElement(el, { throwOnError: false });
        const dictionaryLinks: HTMLLinkElement[] = Array.from(el.getElementsByClassName("msr-dictionary-link"));
        dictionaryLinks.forEach((link: HTMLLinkElement) => {
          link.addEventListener("click", (e) => {
            if (link.id == null) {
              return;
            }
            e.preventDefault();
            setLinkClicked(link);
            setOpen(true);
          });
        });
      }
    },
    [html]
  );
  return (
    <>
      <article
        id={id}
        ref={onRef}
        className={classNames("msr-html-from-string", className)}
        dangerouslySetInnerHTML={{ __html: sanitize(html) }}
      />
      <DictionaryModal open={open} close={() => setOpen(!open)} link={linkClicked} />
    </>
  );
};
