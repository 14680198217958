import { Texts } from "config/texts/index";
import { QuestionType } from "models/quiz";

export const textsEN: Texts = {
  howToTitle: "Take advantage of the opportunities in MyStudyRoom",
  howToBodyHTML: `
<p>Welcome to your study room! Here you will find what you need for an effective preparation for the exam in micro- and macroeconomics. The subjects are pedagogically explained by our specialist, and on as few pages as possible. If you test yourself with our exam-relevant questions and answers, the solution will automatically remove the material you master, so it will be even less for you to read.</p>
<p>Effective, right ?! If you still want to read all the material, of course you always have the opportunity to do so. Whether you answer the questions correctly or incorrectly, you will be served good and easy-to-understand explanations. Some of our students prefer to only do tests until they know the necessities, while others prefer to read the compromised subject explanation.</p>
`,
  howToFooter: "What do you prefer? A combination? Good luck on your exam!",
  email: "Email",
  author: "Author",
  yourCourses: "Your courses",
  courses: "Courses",
  freeCourses: "Free courses",
  otherCourses: "Other courses",
  effectiveLearning: "Effective learning.",
  betterResults: "Better results.",
  loading: "Loading...",
  readMoreButton: "Read more",
  readLessButton: "Hide",
  time: {
    hours: "hours",
    days: "days",
    months: "months",
  },
  course: {
    nav: {
      title: "Content",
      testTitle: "Chapter test",
      repetitionTitle: "Repetition",
    },
    repetition: {
      title: "Repetition",
      info: "Test your knowledge in the course. Based on your results, you will receive recommendations on what you should review. You can take the course test as often as you like!",
      button: "Course repetition",
    },
    about: "About",
    read: "Read",
    takeTest: "Take test",
    buy: "Buy",
    buyDescription: "Price: %PRICE% NOK for %DURATION% months",
    upcoming: "Coming soon",
    trial: "Try %DURATION% for free",
    cantRepeat:
      "You need to allow us to save data and take some tests for this feature to be enabled. You can do that here on the",
    linkToProfilePageLabel: "Profile page",
    compressed: "Compressed course",
    quiz: "Exam relevant tests",
    video: "Video",
    adaptiveLearning: "Adaptive learning",
    progress: {
      disclaimer: "Your progress is calculated based on your results from the chapter tests",
      title: "Your progress",
      chapterTest: {
        title: "Chapter test",
        score: "points",
        notTaken: "Not taken",
      },
    },
  },
  quiz: {
    title: "Chapter test",
    info: "Go through the following tests to check if you have this chapter under control. Good luck! ",
    loading: "Loading new quiz...",
    missing: "No tests for this chapter",
    howto:
      "Test your knowledge in the course. Based on your results, you get recommendations on what you should repeat. You can take the chapter tests as often as you want!",
    start: "Start chapter test",
    testScore: {
      noTests: "No tests in chapter",
      testNotTaken: "Tests not taken",
      points: "points",
    },
    btn: {
      [QuestionType.TextQuestion]: {
        showAnswers: "Show answers",
        hideAnswers: "Hide answers",
      },
      [QuestionType.MultipleChoice]: {
        showAnswers: "Show result",
        hideAnswers: "Try again",
      },
    },
    navigation: {
      previous: "Previous",
      next: "Next",
    },
    question: "Question",
    yourAnswer: "Your answer: ",
    correctAnswer: "Correct answer: ",
    correct: "Very good! ",
    wrong: "Wrong answer. ",
    answer: "The correct answer is:",
  },
  menu: {
    home: "Home",
    continue: "Continue",
    course: "Content",
    search: "Search",
    logout: "Logout",
    dictionary: "Dictionary",
    chapterTest: "Chapter Tests",
    repetition: "Repetition",
    courseTest: "Chapter test",
    profile: "Profile",
    fontOptions: {
      default: "Standard",
      sansSerif: "Sans-Serif",
      serif: "Serif",
      dyslectic: "Dyslectic",
    },
  },
  subchapter: {
    textLabel: "Read chapter",
    multipleChoiceLabel: "Test yourself",
    questionLabel: "Solve tasks",
  },
  dictionary: {
    header: "Dictionary",
    continueToDictionary: "Continue to dictionary",
  },
  search: {
    placeholder: "Search...",
    header: "Search",
    results: "Results",
    course: "Course",
    chapter: "Chapter",
    author: "Author",
  },
  user: {
    consent: {
      title: "Privacy",
      canStoreData: "We gether data to enable features in the service such as Adaptive Learning",
      canStoreDataLabel: "Allow to store data",
      canBeContacted: "Get emails from MyStudyRoom with new features or offers",
      canBeContactedLabel: "Allow sending me emails",
      canTrack: "We gather data about general usage to improve the service",
      canTrackLabel: "Help improve the service",
      showPrivacyInfo: "Show privacy info",
      showCookieInfo: "Show cookie info",
      btnSave: "Save",
      btnAcceptAll: "Accept all",
    },
    profileTitle: "Profile",
    email: "Email",
  },
  payment: {
    heading: "Fill in payment details",
    cardHolder: "Name of card holder",
    submit: "Submit",
    processing: "Processing...",
    authorizing: "Authorizing...",
    success: "Paid!",
    defaultError: "An unknown error occurred",
    error: "Error: ",
  },
};
