import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { NavigationItem, SecondaryLinkButton, SidebarNavigation } from "components";
import Paths, { getMultipleChoiceRoute, getSubchapterRoute, onPage } from "models/paths";
import { IChapter } from "models/course";
import useTexts from "config/texts";

interface CourseNavigationProps {
  courseId: string;
  chapters: IChapter[];
  showQuizButton: boolean;
}

export const CourseNavigation: FunctionComponent<CourseNavigationProps> = ({
  courseId,
  chapters,
  showQuizButton,
}): JSX.Element => {
  const [items, setItems] = useState<NavigationItem[]>([]);
  const texts = useTexts();
  const location = useLocation();
  const repetition = onPage(Paths.Repetition, location.pathname);
  const { subchapterId } = useParams<{ subchapterId: string }>();

  useEffect(() => {
    setItems(
      chapters.map(({ title, subchapters, number }) => ({
        title: `${number}. ${title}`,
        active: subchapters?.some((s) => s.id === subchapterId),
        subitems: subchapters?.map((subchapter) => ({
          prefix: `${number}.${subchapter.number}`,
          title: subchapter.title,
          active: subchapter.id === subchapterId,
          href: getSubchapterRoute(courseId, subchapter.id, repetition),
        })),
      }))
    );
  }, [chapters, courseId, subchapterId, repetition]);

  const getTitle = () => {
    if (repetition) {
      return texts.course.nav.repetitionTitle;
    }
    return texts.course.nav.title;
  };

  return (
    <>
      <SidebarNavigation title={getTitle()} items={items} />
      {showQuizButton && (
        <SecondaryLinkButton
          style={{ margin: "20px 0" }}
          label={texts.quiz.title}
          to={getMultipleChoiceRoute(courseId)}
        />
      )}
    </>
  );
};

export default CourseNavigation;
