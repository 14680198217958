import { textsEN } from "config/texts/texts.en";
import { textsNO } from "config/texts/texts.no";
import { useStateValue } from "context/state.context";
import { QuestionType } from "models/quiz";

export enum Languages {
  NO = "no",
  EN = "en",
}

export interface Texts {
  howToTitle: string;
  howToFooter: string;
  howToBodyHTML: string;
  email: string;
  author: string;
  yourCourses: string;
  courses: string;
  freeCourses: string;
  otherCourses: string;
  effectiveLearning: string;
  betterResults: string;
  loading: string;
  time: {
    hours: string;
    days: string;
    months: string;
  };
  readMoreButton: string;
  readLessButton: string;
  course: {
    nav: {
      title: string;
      testTitle: string;
      repetitionTitle: string;
    };
    repetition: {
      title: string;
      info: string;
      button: string;
    };
    about: string;
    read: string;
    takeTest: string;
    buy: string;
    buyDescription: string;
    upcoming: string;
    trial: string;
    cantRepeat: string;
    linkToProfilePageLabel: string;
    compressed: string;
    quiz: string;
    video: string;
    adaptiveLearning: string;
    progress: {
      title: string;
      disclaimer: string;
      chapterTest: {
        title: string;
        score: string;
        notTaken: string;
      };
    };
  };
  quiz: {
    title: string;
    info: string;
    loading: string;
    missing: string;
    howto: string;
    start: string;
    testScore: {
      noTests: string;
      testNotTaken: string;
      points: string;
    };
    btn: {
      [QuestionType.TextQuestion]: {
        showAnswers: string;
        hideAnswers: string;
      };
      [QuestionType.MultipleChoice]: {
        showAnswers: string;
        hideAnswers: string;
      };
    };
    navigation: {
      previous: string;
      next: string;
    };
    question: string;
    yourAnswer: string;
    correctAnswer: string;
    correct: string;
    wrong: string;
    answer: string;
  };
  menu: {
    home: string;
    continue: string;
    course: string;
    search: string;
    logout: string;
    dictionary: string;
    chapterTest: string;
    repetition: string;
    courseTest: string;
    profile: string;
    fontOptions: {
      default: string;
      sansSerif: string;
      serif: string;
      dyslectic: string;
    };
  };
  subchapter: {
    textLabel: string;
    multipleChoiceLabel: string;
    questionLabel: string;
  };
  dictionary: {
    header: string;
    continueToDictionary: string;
  };
  search: {
    placeholder: string;
    header: string;
    results: string;
    course: string;
    chapter: string;
    author: string;
  };
  user: {
    consent: {
      title: string;
      canStoreData: string;
      canStoreDataLabel: string;
      canBeContacted: string;
      canBeContactedLabel: string;
      canTrack: string;
      canTrackLabel: string;
      showPrivacyInfo: string;
      showCookieInfo: string;
      btnSave: string;
      btnAcceptAll: string;
    };
    profileTitle: string;
    email: string;
  };
  payment: {
    heading: string;
    cardHolder: string;
    submit: string;
    processing: string;
    authorizing: string;
    success: string;
    defaultError: string;
    error: string;
  };
}

const useTexts = () => {
  const { state } = useStateValue();
  const { language } = state;

  switch (language) {
    case Languages.EN:
      return textsEN;
    case Languages.NO:
    default:
      return textsNO;
  }
};

export default useTexts;
