import React from "react";
import classNames from "classnames";

interface RadioButtonProps {
  name: string;
  value: string;
  checked: boolean;
  setValue: (value: string) => void;
  disabled?: boolean;
  highlight?: boolean;
  children: React.ReactNode;
}

const RadioButton = ({
  name,
  value,
  checked,
  setValue,
  disabled,
  highlight,
  children,
}: RadioButtonProps): JSX.Element => (
  <label className={classNames("msr-radio-button", { highlight, disabled })}>
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={(e) => setValue(e?.target?.value)}
      disabled={disabled}
    />
    {children}
    <span className="checkmark" />
  </label>
);

export default RadioButton;
