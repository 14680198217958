import React, { SVGProps } from "react";

const LineHeightLargeIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="28"
    height="35"
    stroke="#66696D"
    strokeWidth="2"
    {...props}
  >
    <rect x="1" y="1" width="90%" height="90%" rx="2" />
    <path d="M7 8h14M7 17h14M7 26h14" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LineHeightLargeIcon;
