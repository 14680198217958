import ExpandIcon from "icons/expand.icon";
import { ITestScores } from "models/course";
import { useState } from "react";
import { SmallProgressBar } from "./progressBar";

interface ProgressAccordianProps {
  number: number;
  title: string;
  progress?: ITestScores;
  children: React.ReactNode;
}

const ProgressAccordion = ({ number, title, progress, children }: ProgressAccordianProps) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <ProgressAccordionHeader
        number={number}
        title={title}
        progress={progress}
        onClick={() => setOpen(!open)}
        open={open}
      />
      {open && children}
    </>
  );
};

interface ProgressAccordianHeaderProps {
  number: number;
  title: string;
  progress?: ITestScores;
  onClick: () => void;
  open: boolean;
}

const ProgressAccordionHeader = ({ number, title, progress, onClick, open }: ProgressAccordianHeaderProps) => {
  return (
    <button className="msr-progress-accordion-header" onClick={onClick}>
      <p>
        {number} {title}
      </p>
      {progress ? (
        <SmallProgressBar length={progress.possibleScore} currentIndex={progress.score} color="yellow" />
      ) : null}
      <ExpandIcon className={`msr-progress-accordion-header__icon`} transform={open ? "rotate(180)" : ""} />
    </button>
  );
};

export default ProgressAccordion;
