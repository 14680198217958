import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { Card, ExpandableText, SecondaryLinkButton } from "components";
import CourseProgress from "components/courseProgress";
import useTexts from "config/texts";
import { Subchapter } from "containers";
import { IChapter, ICourse, ICourseProgress, ISubchapterTeaser } from "models/course";
import { getCourseRoute, getSubchapterRoute } from "models/paths";

interface SubchapterBodyProps {
  course: ICourse;
  chapter?: IChapter;
  subchapterTeaser?: ISubchapterTeaser;
  progress?: ICourseProgress;
  isRepetition?: boolean;
}

const SubchapterBody: FunctionComponent<SubchapterBodyProps> = ({
  course,
  chapter,
  subchapterTeaser,
  progress,
  isRepetition = false,
}) => {
  const texts = useTexts();
  const { subchapterId } = useParams<{ subchapterId: string }>();
  const { id, aboutHtml } = course;

  if (subchapterId == null || chapter == null || subchapterTeaser == null) {
    return (
      <>
        <h2>{texts.course.about}</h2>
        <ExpandableText text={aboutHtml} />
        {progress ? (
          <>
            <CourseProgress progress={progress} />
            <section className="msr-subchapter__repetition">
              <h3 className="msr-subchapter__repetition__title">{texts.course.repetition.title}</h3>
              <Card>
                <p>{texts.course.repetition.info}</p>
                <SecondaryLinkButton
                  to={getCourseRoute(id, true)}
                  label={texts.course.repetition.button}
                ></SecondaryLinkButton>
              </Card>
            </section>
          </>
        ) : null}
      </>
    );
  }

  return (
    <Subchapter
      courseId={id}
      subchapterId={subchapterId}
      chapterNumber={chapter.number}
      chapterTitle={chapter.title}
      subchapterNumber={subchapterTeaser.number}
      subchapterTitle={subchapterTeaser.title}
      previousHref={getSubchapterRoute(id, subchapterTeaser?.previous ?? "", isRepetition)}
      nextHref={
        subchapterTeaser?.next == null ? undefined : getSubchapterRoute(id, subchapterTeaser.next, isRepetition)
      }
    />
  );
};

export default SubchapterBody;
