enum Paths {
  CourseRoot = "/course/:courseId/*",
  Course = "/course/:courseId/content/:subchapterId",
  MultipleChoice = "/course/:courseId/multipleChoice/:chapterId",
  Repetition = "/course/:courseId/repetition/:subchapterId",
  Author = "/author/:authorId",
  Dictionary = "/dictionary",
  Search = "/search",
  Profile = "/profile",
  Home = "/",
}

export enum CoursePaths {
  Course = "/content",
  CourseFull = "/content/:subchapterId",
  Repetition = "/repetition",
  RepetitionFull = "/repetition/:subchapterId",
  MultipleChoice = "/multipleChoice",
  MultipleChoiceFull = "/multipleChoice/:chapterId",
}

const courseRoute = (repetition: boolean) => (repetition ? Paths.Repetition : Paths.Course);

export const getAuthorRoute = (authorId: string) => `/author/${authorId}`;
export const getSubchapterRoute = (courseId: string, subchapterId: string, repetition: boolean = false) =>
  courseRoute(repetition)
    .replace(":courseId", courseId ?? "")
    .replace(":subchapterId", subchapterId ?? "");
export const getMultipleChoiceRoute = (courseId: string | null | undefined, chapterId?: string | null): string =>
  Paths.MultipleChoice.replace(":courseId", courseId ?? "").replace(":chapterId", chapterId ?? "");
export const getCourseRoute = (courseId: string | null | undefined, repetition: boolean = false) =>
  courseRoute(repetition)
    .replace(":courseId", courseId ?? "")
    .replace(":subchapterId", "");

export enum AppAnchors {
  AppContent = "msr-app-content",
  PageContent = "msr-page-content",
  Text = "msr-text-anchor",
  MultipleChoice = "msr-multiple-choice-anchor",
  Questions = "msr-questions-anchor",
}

export const onPage = (route: Paths, pathname: string) => {
  switch (route) {
    case Paths.Home:
      return pathname === "/";
    case Paths.Search:
      return pathname === Paths.Search;
    case Paths.Profile:
      return pathname === Paths.Profile;
    case Paths.Author:
      return pathname.startsWith("/author");
    case Paths.CourseRoot:
      return pathname.startsWith("/course");
    case Paths.Course:
      return pathname.startsWith("/course") && pathname.includes("/content");
    case Paths.Dictionary:
      return pathname.startsWith("/dictionary");
    case Paths.Repetition:
      return pathname.startsWith("/course") && pathname.includes("/repetition");
    case Paths.MultipleChoice:
      return pathname.startsWith("/course") && pathname.includes("/multipleChoice");
    default:
      return false;
  }
};

export default Paths;
