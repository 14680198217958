import { Actions, IAction } from "context/state.context";
import { SearchResponse } from "models/searchResponse";

export interface ISearchState {
  searchString?: string;
  searchResponse?: SearchResponse;
}

export type ISearchAction =
  | {
      type: Actions.ADD_SEARCH_RESPONSE;
      payload: SearchResponse;
    }
  | {
      type: Actions.ADD_SEARCH_STRING;
      payload: string;
    };

export const searchReducer = (searchState: ISearchState | undefined, action: IAction): ISearchState | undefined => {
  switch (action.type) {
    case Actions.ADD_SEARCH_STRING:
      return { ...searchState, searchString: action.payload };
    case Actions.ADD_SEARCH_RESPONSE:
      return { ...searchState, searchResponse: action.payload };
    default:
      return searchState;
  }
};
