import useTexts from "config/texts";
import ExpandIcon from "icons/expand.icon";
import { useState } from "react";
import { TertiaryButton } from "./button";
import { DangerousTextBody } from "./dangerousHTML";

interface ExpandableTextProps {
  text: string;
}

const ExpandableText = ({ text }: ExpandableTextProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const texts = useTexts();

  return (
    <>
      <DangerousTextBody className={`msr-expandable-text__text ${open ? "open" : ""}`} html={text} />
      <TertiaryButton
        className="msr-expandable-text__button"
        onClick={() => setOpen(!open)}
        label={open ? texts.readLessButton : texts.readMoreButton}
        icon={<ExpandIcon className="msr-expandable-text__button__icon" transform={open ? "rotate(180)" : ""} />}
        iconRight
      />
    </>
  );
};

export default ExpandableText;
