import React from "react";
import { useLocation } from "react-router";

import { useAppLayout } from "hooks/layout.hooks";
import { useIds } from "hooks/location.hooks";
import { Header, SidebarMenu } from "components";
import { MenuItemProps } from "models/menuItemProps";
import { useStateValue } from "context/state.context";
import { LAST_LOCATION } from "context/location.context";
import {
  BookmarkIcon,
  DictionaryIcon,
  DocumentIcon,
  HomeIcon,
  PenOnPaperIcon,
  ProfileIcon,
  RepeatIcon,
  SearchIcon,
} from "icons";
import Paths, { getCourseRoute, getMultipleChoiceRoute, onPage } from "models/paths";
import useTexts from "config/texts";

import "./appLayout.scss";

interface AppLayoutProps {
  children: React.ReactNode;
}

const isActive = (route: Paths) => (pathname: string) => onPage(route, pathname);

const toggleSidebar = () => document.getElementsByClassName("msr-page-sidebar")?.[0]?.classList.toggle("hide");

const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
  useAppLayout();
  const { menu } = useTexts();
  const { state } = useStateValue();
  const location = useLocation();

  const { courseId } = useIds();
  const onCoursePage =
    onPage(Paths.CourseRoot, location.pathname) && state?.course?.id === courseId && state?.course?.adaptiveLearning;

  const lastCourse = state.location[LAST_LOCATION];
  const menuItems: MenuItemProps[] = [
    { label: menu.home, Icon: HomeIcon, to: Paths.Home, isActive: isActive(Paths.Home) },
    {
      label: menu.continue,
      Icon: BookmarkIcon,
      to: lastCourse || "",
      hide: lastCourse == null || onCoursePage,
    },
    {
      label: menu.course,
      Icon: DocumentIcon,
      to: getCourseRoute(courseId),
      isActive: isActive(Paths.Course),
      onClick: toggleSidebar,
      hide: !onCoursePage,
    },
    {
      label: menu.repetition,
      Icon: RepeatIcon,
      to: getCourseRoute(courseId, true),
      isActive: isActive(Paths.Repetition),
      onClick: toggleSidebar,
      hide: !onCoursePage,
    },
    {
      label: menu.courseTest,
      Icon: PenOnPaperIcon,
      to: getMultipleChoiceRoute(courseId, undefined),
      isActive: isActive(Paths.MultipleChoice),
      onClick: toggleSidebar,
      hide: !onCoursePage,
    },
    { label: menu.dictionary, Icon: DictionaryIcon, to: Paths.Dictionary, isActive: isActive(Paths.Dictionary) },
    { label: menu.search, Icon: SearchIcon, to: Paths.Search, isActive: isActive(Paths.Search) },
    { label: menu.profile, Icon: ProfileIcon, to: Paths.Profile, isActive: isActive(Paths.Profile) },
  ];

  return (
    <>
      <Header menuItems={menuItems} />
      <SidebarMenu menuItems={menuItems} />
      {children}
    </>
  );
};

export default AppLayout;
