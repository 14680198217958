import React from "react";
import { Link } from "react-router-dom";

import { ICourse } from "models/course";
import { getAuthorRoute, getCourseRoute } from "models/paths";
import useTexts from "config/texts";

interface CourseHeaderProps {
  course?: ICourse;
}

const CourseHeader = ({ course }: CourseHeaderProps) => {
  const texts = useTexts();
  if (course == null) {
    return null;
  }

  return (
    <>
      <Link to={getCourseRoute(course.id)} title={texts.course.about}>
        <h1>{course.title}</h1>
      </Link>
      <p>
        {texts.author} <Link to={getAuthorRoute(course.authorId)}>{course.author}</Link>
      </p>
    </>
  );
};

export default CourseHeader;
