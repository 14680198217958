import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PrivateApi } from "api/service";
import { MultipleChoice, NavigationItem, PageLayout, SecondaryLinkButton, SidebarNavigation } from "components";
import { useStateValue } from "context/state.context";
import { useCourse } from "hooks/course.hooks";
import { IMultipleChoiceQuestion } from "models/quiz";
import { ICourse, ITestScores } from "models/course";
import { getCourseRoute, getMultipleChoiceRoute } from "models/paths";
import useTexts from "config/texts";

const useChapterMenu = (course?: ICourse, chapterId?: string) => {
  const [menuItems, setMenuItems] = useState<NavigationItem[]>([]);
  const texts = useTexts();
  const { state } = useStateValue();
  const canStoreData = state.userState?.canStoreData ?? false;

  useEffect(() => {
    const getSubtitle = (testTaken?: ITestScores) => {
      if (testTaken == null) {
        return texts.quiz.testScore.noTests;
      }
      if (testTaken.possibleScore === 0) {
        return texts.quiz.testScore.testNotTaken;
      }
      return `${testTaken.score}/${testTaken.possibleScore} ${texts.quiz.testScore.points}`;
    };

    setMenuItems(
      course?.chapters?.map(({ title, id, number, testTaken }) => {
        return {
          prefix: `${number}.`,
          title: title,
          subtitle: canStoreData ? getSubtitle(testTaken) : undefined,
          active: id === chapterId,
          href: getMultipleChoiceRoute(course?.id, id),
        };
      }) ?? []
    );
  }, [course?.chapters, course?.id, chapterId, texts, canStoreData]);

  return menuItems;
};

const TestNavigation = ({ course, menuItems }: { course?: ICourse; menuItems: NavigationItem[] }) => {
  const texts = useTexts();
  return (
    <>
      <SidebarNavigation title={texts.course.nav.testTitle} items={menuItems} />
      {course?.adaptiveLearning && (
        <SecondaryLinkButton
          style={{ margin: "20px 0" }}
          label={texts.menu.repetition}
          to={getCourseRoute(course?.id, true)}
        />
      )}
    </>
  );
};

const TestBody = ({
  questions,
  courseId,
  chapterId,
  fetchChapterQuiz,
}: {
  questions: IMultipleChoiceQuestion[];
  courseId: string;
  chapterId: string;
  fetchChapterQuiz: (id: string, chapterId: string) => Promise<void>;
}) => {
  const texts = useTexts();
  if ((questions.length || 0) <= 0) {
    return <p>{texts.quiz.missing}</p>;
  }

  return (
    <>
      <p>{texts.quiz.info}</p>
      <MultipleChoice
        courseId={courseId}
        documentId={chapterId}
        questions={questions}
        onRetry={() => fetchChapterQuiz(courseId, chapterId)}
        saveAnswers
      />
    </>
  );
};

const MultipleChoicePage: FunctionComponent = () => {
  const [questions, setQuestions] = useState<IMultipleChoiceQuestion[]>();
  const { chapterId } = useParams<{ courseId: string; chapterId?: string }>();
  const { course } = useCourse();
  const menuItems = useChapterMenu(course, chapterId);
  const texts = useTexts();

  const fetchChapterQuiz = (id: string, chapterId: string): Promise<void> =>
    PrivateApi.fetchMultipleChoice(id, chapterId).then((res: IMultipleChoiceQuestion[]) => setQuestions(res));

  useEffect(() => {
    if (course?.id != null && chapterId != null) {
      fetchChapterQuiz(course?.id, chapterId);
    }
  }, [course?.id, chapterId]);

  return (
    <PageLayout
      sidebar={<TestNavigation course={course} menuItems={menuItems} />}
      main={
        <>
          <h2>{texts.quiz.title}</h2>
          {questions == null || course?.id == null || chapterId == null ? (
            <>
              <p>{texts.quiz.howto}</p>
              <SecondaryLinkButton
                style={{ margin: "20px 0" }}
                label={texts.quiz.start}
                to={menuItems?.[0]?.href ?? ""}
              />
            </>
          ) : (
            <TestBody
              courseId={course?.id}
              chapterId={chapterId}
              questions={questions}
              fetchChapterQuiz={fetchChapterQuiz}
            />
          )}
        </>
      }
    />
  );
};

export default MultipleChoicePage;
