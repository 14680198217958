import { ChangeEvent, MouseEventHandler, useState } from "react";

import { Accordion, CookiesInfo, PrivacyInfo, SecondaryButton, ToggleSwitch } from "components";
import useTexts from "config/texts";
import { PrivateApi } from "api/service";
import { Actions, useStateValue } from "context/state.context";
import { IUserConsents } from "models/user";

interface IUserConsentProps {
  onSave?: () => void;
  style?: {};
}

export const UserConsents = (props: IUserConsentProps): JSX.Element => {
  const { state, dispatch } = useStateValue();
  const [canStoreData, setCanStoreData] = useState<boolean>(state.userState?.canStoreData || false);
  const [canBeContacted, setCanBeContacted] = useState<boolean>(state.userState?.canBeContacted || false);
  const [canTrack, setCanTrack] = useState<boolean>(state.userState?.canTrack || false);
  const texts = useTexts();

  const onChangeStoreData = (e: ChangeEvent<HTMLInputElement>): void => setCanStoreData(!canStoreData);
  const onChangeCanBeContacted = (): void => setCanBeContacted(!canBeContacted);
  const onChangeCanTrack = (): void => setCanTrack(!canTrack);

  const save = (consents: IUserConsents) => {
    PrivateApi.saveUserConsents(consents).then((user) => dispatch({ type: Actions.UPDATE_USER, payload: user }));
  };

  const acceptAll: MouseEventHandler<HTMLButtonElement> = () => {
    setCanStoreData(true);
    setCanBeContacted(true);
    setCanTrack(true);
    save({
      canStoreData: true,
      canBeContacted: true,
      canTrack: true,
      hasPromptedConsent: true,
    });
  };

  const accept = () => {
    save({
      canStoreData,
      canBeContacted,
      canTrack,
      hasPromptedConsent: true,
    });
  };

  return (
    <div style={props.style}>
      <h3>{texts.user.consent.title}</h3>

      <p>{texts.user.consent.canStoreData}</p>
      <div className="consent-toggle">
        <label>{texts.user.consent.canStoreDataLabel}</label>
        <ToggleSwitch onChange={onChangeStoreData} checked={canStoreData} />
      </div>

      <p>{texts.user.consent.canBeContacted}</p>
      <div className="consent-toggle">
        <label>{texts.user.consent.canBeContactedLabel}</label>
        <ToggleSwitch onChange={onChangeCanBeContacted} checked={canBeContacted} />
      </div>

      <p>{texts.user.consent.canTrack}</p>
      <div className="consent-toggle">
        <label>{texts.user.consent.canTrackLabel}</label>
        <ToggleSwitch onChange={onChangeCanTrack} checked={canTrack} />
      </div>

      <Accordion title={texts.user.consent.showPrivacyInfo} scroll>
        <PrivacyInfo />
      </Accordion>
      <Accordion title={texts.user.consent.showCookieInfo} scroll>
        <CookiesInfo />
      </Accordion>

      <div style={{ display: "flex", gap: "2rem", margin: "2rem 0" }}>
        <SecondaryButton onClick={accept} label={texts.user.consent.btnSave} />
        <SecondaryButton onClick={acceptAll} label={texts.user.consent.btnAcceptAll} />
      </div>
    </div>
  );
};
