import { Actions, IAction } from "context/state.context";

export enum FontFamily {
  AmpleSoft = "'ample-soft', 'proxima-nova', sans-serif",
  ProximaNova = "'proxima-nova', sans-serif",
  Merriweather = "'Merriweather', serif",
  OpenDyslexic = "'opendyslexic', 'proxima-nova', sans-serif",
}

export const getFontClass = (fontFamily: FontFamily) => {
  switch (fontFamily) {
    case FontFamily.ProximaNova:
      return "msr-font-sans-serif";
    case FontFamily.Merriweather:
      return "msr-font-serif";
    case FontFamily.OpenDyslexic:
      return "msr-font-dyslectic";
    case FontFamily.AmpleSoft:
    default:
      return "msr-font-standard";
  }
};

export enum FontSize {
  Small = ".8rem",
  Medium = "1rem",
  Large = "1.5rem",
}

export enum LineHeight {
  Small = "1.5rem",
  Medium = "2rem",
  Large = "2.5rem",
}

export interface IFontState {
  fontFamily: FontFamily;
  fontSize: FontSize;
  lineHeight: LineHeight;
}

export type IFontAction =
  | {
      type: Actions.UPDATE_FONT_FAMILY;
      payload: FontFamily;
    }
  | {
      type: Actions.UPDATE_FONT_SIZE;
      payload: FontSize;
    }
  | {
      type: Actions.UPDATE_LINE_HEIGHT;
      payload: LineHeight;
    };

export const defaultFontState: IFontState = {
  fontFamily: FontFamily.AmpleSoft,
  fontSize: FontSize.Medium,
  lineHeight: LineHeight.Medium,
};

export const fontReducer = (fontState: IFontState, action: IAction): IFontState => {
  switch (action.type) {
    case Actions.UPDATE_FONT_FAMILY:
      return {
        ...fontState,
        fontFamily: action.payload,
      };
    case Actions.UPDATE_FONT_SIZE:
      return {
        ...fontState,
        fontSize: action.payload,
      };
    case Actions.UPDATE_LINE_HEIGHT:
      return {
        ...fontState,
        lineHeight: action.payload,
      };
    default:
      return fontState;
  }
};
