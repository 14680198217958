import React, { SVGProps } from "react";

const DocumentIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="52.19 48.09 38 46" {...props}>
    <path d="M79.19 49.09h-22c-2.21 0-4 1.79-4 4v36c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-29l-10-11zm8 40a2 2 0 01-2 2h-28a2 2 0 01-2-2v-36c0-1.1.9-2 2-2h18v10c0 1.1.9 2 2 2h10v26zm-10-28v-10h1l9 10h-10zm-15-1h5c.55 0 1-.45 1-1s-.45-1-1-1h-5c-.55 0-1 .45-1 1 0 .56.45 1 1 1zm12 22h-12c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1zm6-8h-18c-.55 0-1 .45-1 1s.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1zm-19-7c0 .55.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1h-18c-.55 0-1 .45-1 1z" />
    <path d="M79.19 49.09h-22c-2.21 0-4 1.79-4 4v36c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-29l-10-11zm8 40a2 2 0 01-2 2h-28a2 2 0 01-2-2v-36c0-1.1.9-2 2-2h18v10c0 1.1.9 2 2 2h10v26zm-10-28v-10h1l9 10h-10zm-15-1h5c.55 0 1-.45 1-1s-.45-1-1-1h-5c-.55 0-1 .45-1 1 0 .56.45 1 1 1zm12 22h-12c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1zm6-8h-18c-.55 0-1 .45-1 1s.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1zm-19-7c0 .55.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1h-18c-.55 0-1 .45-1 1z" />
  </svg>
);

export default DocumentIcon;
