import React, { ComponentType, FunctionComponent, useEffect } from "react";
import { MsalProvider, useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { Configuration, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";

const AUTHORITY = `https://msroom.b2clogin.com`;
const TENANT = "msroom.onmicrosoft.com";
const SIGN_IN_AUTHORITY = `${AUTHORITY}/tfp/${TENANT}/B2C_1_SUSI_2`;
const RESET_PASSWORD_AUTHORITY = `${AUTHORITY}/tfp/${TENANT}/B2C_1_Reset_Password`;
const TOKEN_KEY = "msr-access-token";
const SCOPES = ["openid", `https://msroom.onmicrosoft.com/${window.env.MSAL_CLIENT_ID}/Read`];

// Msal Configurations
const config: Configuration = {
  auth: {
    authority: SIGN_IN_AUTHORITY,
    clientId: window.env.MSAL_CLIENT_ID,
    redirectUri: window.env.REACT_APP_AUTH_REDIRECT_URL,
    knownAuthorities: [AUTHORITY],
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

enum Errors {
  FORGOTTEN_PASSWORD = "AADB2C90118",
  CANCELLED_LOGIN = "AADB2C90091",
}

const authProvider = new PublicClientApplication(config);

const SecureApplication: FunctionComponent = ({ children }): JSX.Element => {
  const isAuthenticated = useIsAuthenticated();
  const { error, login } = useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    if (error?.errorMessage?.includes(Errors.FORGOTTEN_PASSWORD)) {
      login(InteractionType.Redirect, { authority: RESET_PASSWORD_AUTHORITY, scopes: SCOPES });
    }
    if (error?.errorMessage?.includes(Errors.CANCELLED_LOGIN)) {
      login(InteractionType.Redirect);
    }
  }, [error, login]);

  if (!isAuthenticated) {
    return <></>;
  }

  return <>{children}</>;
};

export default class Authentication {
  static secure(Component: ComponentType<any>): FunctionComponent {
    return () => (
      <MsalProvider instance={authProvider}>
        <SecureApplication>
          <Component />
        </SecureApplication>
      </MsalProvider>
    );
  }

  static async getToken(): Promise<string | null> {
    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    return accessToken == null ? Authentication.getAccessToken() : Promise.resolve(accessToken);
  }

  static logout(): Promise<void> {
    sessionStorage.removeItem(TOKEN_KEY);
    return authProvider.logoutRedirect();
  }

  static async authenticated(): Promise<boolean> {
    return (await Authentication.getAccessToken()) != null;
  }

  static getUserId(): string | undefined {
    return Authentication.getAccount()?.localAccountId;
  }

  private static async getAccessToken(): Promise<string | null> {
    try {
      const response = await authProvider.acquireTokenSilent({ scopes: SCOPES, account: Authentication.getAccount() });
      const token = response.accessToken;
      sessionStorage.setItem(TOKEN_KEY, token);
      return token;
    } catch (e) {
      sessionStorage.removeItem(TOKEN_KEY);
      console.error("Failed to fetch token", e);
      return null;
    }
  }

  private static getAccount(): AccountInfo | undefined {
    const activeAccount = authProvider.getActiveAccount();
    const accounts = authProvider.getAllAccounts();
    return (activeAccount || accounts?.[0]) ?? undefined;
  }
}
