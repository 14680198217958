import { Actions, IAction } from "context/state.context";
import { Themes } from "config/themes/themes.config";

export interface IThemeAction {
  type: Actions.UPDATE_THEME;
  payload: Themes;
}

export const themeReducer = (theme: Themes, action: IAction): Themes => {
  switch (action.type) {
    case Actions.UPDATE_THEME:
      return action.payload;
    default:
      return theme;
  }
};
