import React, { CSSProperties } from "react";
import classNames from "classnames";

import { FontFamily, FontSize, LineHeight } from "context/font.context";
import { useDispatchFontFamily, useDispatchFontSize, useDispatchLineHeight, useFontState } from "hooks/font.hooks";
import {
  FontSizeLargeIcon,
  FontSizeMediumIcon,
  FontSizeSmallIcon,
  LineHeightLargeIcon,
  LineHeightMediumIcon,
  LineHeightSmallIcon,
} from "icons";
import uniqueId from "utils/unique.util";
import useTexts from "config/texts";

interface Settings {
  label: React.ReactNode | string;
  setSetting: () => void;
  active: boolean;
  style?: CSSProperties;
}

const ReadSetting = ({ settings = [], column = false }: { settings: Settings[]; column?: boolean }) => (
  <div className={classNames("msr-font-setting-menu", { column })}>
    {settings.map((s) => (
      <button
        key={uniqueId("setting")}
        className={classNames({ active: s.active })}
        onClick={s.setSetting}
        style={s.style}
      >
        {s.label}
      </button>
    ))}
  </div>
);

export const FontSizeSettings = (): JSX.Element => {
  const fontState = useFontState();
  const setFontSize = useDispatchFontSize();
  return (
    <ReadSetting
      settings={[
        {
          active: fontState.fontSize === FontSize.Small,
          label: <FontSizeSmallIcon style={{ stroke: "none" }} />,
          setSetting: () => setFontSize(FontSize.Small),
        },
        {
          active: fontState.fontSize === FontSize.Medium,
          label: <FontSizeMediumIcon style={{ stroke: "none" }} />,
          setSetting: () => setFontSize(FontSize.Medium),
        },
        {
          active: fontState.fontSize === FontSize.Large,
          label: <FontSizeLargeIcon style={{ stroke: "none" }} />,
          setSetting: () => setFontSize(FontSize.Large),
        },
      ]}
    />
  );
};

export const LineHeightSettings = (): JSX.Element => {
  const fontState = useFontState();
  const setLineHeight = useDispatchLineHeight();
  return (
    <ReadSetting
      settings={[
        {
          active: fontState.lineHeight === LineHeight.Small,
          label: <LineHeightSmallIcon style={{ fill: "none" }} />,
          setSetting: () => setLineHeight(LineHeight.Small),
        },
        {
          active: fontState.lineHeight === LineHeight.Medium,
          label: <LineHeightMediumIcon style={{ fill: "none" }} />,
          setSetting: () => setLineHeight(LineHeight.Medium),
        },
        {
          active: fontState.lineHeight === LineHeight.Large,
          label: <LineHeightLargeIcon style={{ fill: "none" }} />,
          setSetting: () => setLineHeight(LineHeight.Large),
        },
      ]}
    />
  );
};

export const FontFamilySettings = (): JSX.Element => {
  const texts = useTexts();
  const fontState = useFontState();
  const setFontFamily = useDispatchFontFamily();
  return (
    <ReadSetting
      settings={[
        {
          active: fontState.fontFamily === FontFamily.AmpleSoft,
          label: texts.menu.fontOptions.default,
          style: { fontFamily: FontFamily.AmpleSoft, textAlign: "left" },
          setSetting: () => setFontFamily(FontFamily.AmpleSoft),
        },
        {
          active: fontState.fontFamily === FontFamily.ProximaNova,
          label: texts.menu.fontOptions.sansSerif,
          style: { fontFamily: FontFamily.ProximaNova, textAlign: "left" },
          setSetting: () => setFontFamily(FontFamily.ProximaNova),
        },
        {
          active: fontState.fontFamily === FontFamily.Merriweather,
          label: texts.menu.fontOptions.serif,
          style: { fontFamily: FontFamily.Merriweather, textAlign: "left" },
          setSetting: () => setFontFamily(FontFamily.Merriweather),
        },
        {
          active: fontState.fontFamily === FontFamily.OpenDyslexic,
          label: texts.menu.fontOptions.dyslectic,
          style: { fontFamily: FontFamily.OpenDyslexic, textAlign: "left" },
          setSetting: () => setFontFamily(FontFamily.OpenDyslexic),
        },
      ]}
      column
    />
  );
};
