import React, { FunctionComponent, useState } from "react";
import classNames from "classnames";

import { TertiaryButton } from "components";

interface AccordionProps {
  title: string;
  open?: boolean;
  scroll?: boolean;
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  title,
  open: init,
  children,
  scroll = false,
}): JSX.Element => {
  const [open, setOpen] = useState(init);
  return (
    <>
      <TertiaryButton
        className={classNames("msr-accordion-button", { open })}
        onClick={() => setOpen(!open)}
        label={title}
      />
      <div className={classNames("msr-accordion-content", { scroll, open })}>{children}</div>
    </>
  );
};

export default Accordion;
