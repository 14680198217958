import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PublicApi } from "api/service";
import { IAuthor } from "models/author";
import { DangerousTextBody, PageLayout } from "components";

const AuthorPage: FunctionComponent = () => {
  const [author, setAuthors] = useState<IAuthor>();
  const { authorId } = useParams<{ authorId?: string }>();

  useEffect(() => {
    if (authorId != null) {
      PublicApi.fetchAuthor(authorId).then((res: IAuthor) => setAuthors(res));
    }
  }, [authorId, setAuthors]);

  if (author == null) {
    return null;
  }

  const { name, imageUrl, email, aboutHtml } = author;
  return (
    <PageLayout
      main={
        <>
          <h1>{name}</h1>
          {imageUrl && <img src={imageUrl} alt="Author" style={{ maxHeight: 200 }} />}
          {email && (
            <a href={"mailto:" + email} style={{ display: "block" }}>
              {email}
            </a>
          )}
          <DangerousTextBody html={aboutHtml} />
        </>
      }
    />
  );
};

export default AuthorPage;
