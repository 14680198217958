import { Texts } from "config/texts/index";
import { QuestionType } from "models/quiz";

export const textsNO: Texts = {
  howToTitle: "Utnytt mulighetene i MyStudyRoom",
  howToBodyHTML: `
<p>Velkommen til ditt studierom! Her finner du det du trenger for en effektiv forberedelse til eksamen i mikro- og makroøkonomi. Fagene er pedagogisk forklart av vår fagspesialist, og på så få sider som mulig. Hvis du tester deg selv med våre eksamensrelevante spørsmål og svar, vil løsningen automatisk fjerne det stoffet du behersker, så det blir enda mindre for deg å lese.</p>
<p>Effektivt, ikke sant?! Vil du likevel lese alt stoffet, har du selvfølgelig alltid mulighet til det. Uansett om du svarer riktig eller galt på spørsmålene, vil du få gode og lett forståelige forklaringer servert. Noen av våre studenter foretrekker å bare gjøre tester til stoffet sitter, mens andre foretrekker å lese den komprimerte fagforklaringen.</p>
`,
  howToFooter: "Hva foretrekker du? En kombinasjon? Lykke til på eksamen!",
  email: "E-post",
  author: "av",
  yourCourses: "Mine fag",
  courses: "Aktuelle fag",
  freeCourses: "Gratis fag",
  otherCourses: "Andre aktuelle fag",
  effectiveLearning: "Effektiv læring.",
  betterResults: "Bedre resultater.",
  loading: "Laster...",
  readMoreButton: "Les mer",
  readLessButton: "Skjul",
  time: {
    hours: "timer",
    days: "dager",
    months: "måneder",
  },
  course: {
    nav: {
      title: "Innhold",
      testTitle: "Kapitteltest",
      repetitionTitle: "Repetisjon",
    },
    repetition: {
      title: "Repetisjon",
      info: "Test dine kunnskaper i kurset. Basert på dine resultater får du anbefalinger om hva du bør reptere. Du kan ta kurstesetn så ofte du vil!",
      button: "Repeter kurs",
    },
    about: "Om faget",
    read: "Les",
    takeTest: "Ta test",
    buy: "Kjøp",
    buyDescription: "Pris: %PRICE% kr. for %DURATION% måneder",
    upcoming: "Kommer snart",
    trial: "Prøv gratis i %DURATION%",
    cantRepeat:
      "For at vi skal klare å finne ut av hva du kan og ikke kan må du godta at vi lager data om deg og gjennomføre noen tester først. Du kan tillate lagring av data her på",
    linkToProfilePageLabel: "Min side",
    compressed: "Faget komprimert",
    quiz: "Eksamensrelevante tester",
    video: "Video",
    adaptiveLearning: "Skreddersydd læring",
    progress: {
      title: "Din progresjon",
      disclaimer: "Din progresjon er beregnet basert på dine resultater fra kapitteltestene",
      chapterTest: {
        title: "Kapitteltest",
        score: "poeng",
        notTaken: "Ikke gjennomført",
      },
    },
  },
  quiz: {
    title: "Kapitteltest",
    info: "Gå gjennom følgende tester for å sjekke om du har god kontroll på dette kapittelet. Lykke til!",
    loading: "Laster ny test...",
    missing: "Ingen tester for dette kapittelet",
    howto:
      "Test dine kunnskaper i kurset. Basert på dine resultater får du anbefalninger om hva du bør repetere. Du kan ta kapitteltestene så ofte du vil!",
    start: "Start kapitteltest",
    testScore: {
      noTests: "Ingen tester i kapittel",
      testNotTaken: "Test ikke tatt",
      points: "poeng",
    },
    btn: {
      [QuestionType.TextQuestion]: {
        showAnswers: "Vis fasit",
        hideAnswers: "Skjul fasit",
      },
      [QuestionType.MultipleChoice]: {
        showAnswers: "Se resultat",
        hideAnswers: "Prøv igjen",
      },
    },
    navigation: {
      previous: "Forrige",
      next: "Neste",
    },
    question: "Spørsmål",
    yourAnswer: "Du svarte: ",
    correctAnswer: "Riktig svar: ",
    correct: "Kjempebra! ",
    wrong: "Obs. Svaret var feil. ",
    answer: "Riktig svar er:",
  },
  menu: {
    home: "Forside",
    continue: "Fortsett",
    course: "Innhold",
    search: "Søk",
    logout: "Logg ut",
    dictionary: "Ordbok",
    chapterTest: "Kapitel tester",
    repetition: "Repetisjon",
    courseTest: "Kapitteltest",
    profile: "Min Side",
    fontOptions: {
      default: "Standard",
      sansSerif: "Sans-Serif",
      serif: "Serif",
      dyslectic: "Dyslektisk",
    },
  },
  subchapter: {
    textLabel: "Les kapittelet",
    multipleChoiceLabel: "Test deg selv",
    questionLabel: "Gjør oppgaver",
  },
  search: {
    placeholder: "Søk...",
    header: "Søk",
    results: "Treff",
    course: "Fag",
    chapter: "Kapittel",
    author: "Forfatter",
  },
  dictionary: {
    header: "Ordbok",
    continueToDictionary: "Fortsett til ordbok",
  },
  user: {
    consent: {
      title: "Personvern",
      canStoreData:
        "Lagre data og svar fra testene du tar så MyStudyRoom kan tilrettelegge for bedre læring ved f.eks Adaptiv læring hvor MyStudyRoom tar bort de kapitlene som du mestrer",
      canStoreDataLabel: "Tillat lagring av data",
      canBeContacted: "Få eposter om nye kurs, ny funksjonalitet og tilbud",
      canBeContactedLabel: "Tillat å sende meg epost",
      canTrack: "Innsamling av generel bruksdata som vil gi MyStudyRoom mulighet til å forbedre tjenesten",
      canTrackLabel: "Hjelp til å forbedre tjenesten ved å tilatte cookies",
      showPrivacyInfo: "Vis informasjon om personvern",
      showCookieInfo: "Vis informasjon om cookies",
      btnSave: "Lagre",
      btnAcceptAll: "Akseptere alle",
    },
    profileTitle: "Min side",
    email: "Epost",
  },
  payment: {
    heading: "Fyll ut betalingsinformasjon",
    cardHolder: "Navn på korteier",
    submit: "Betal",
    processing: "Prosesserer...",
    authorizing: "Autoriserer...",
    success: "Betalt!",
    defaultError: "Det oppstod en ukjent feil",
    error: "Feilmelding: ",
  },
};
