import React, { FunctionComponent, ReactNode } from "react";

interface DropdownProps {
  buttonLabel: ReactNode | string;
}

const Dropdown: FunctionComponent<DropdownProps> = ({ children, buttonLabel }) => (
  <div className="msr-dropdown">
    <button className="msr-dropdown-btn">{buttonLabel}</button>
    <div className="msr-dropdown-content">{children}</div>
  </div>
);

export default Dropdown;
