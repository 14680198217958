import { useEffect } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

import { Actions, useStateValue } from "context/state.context";

interface UseIdsResponse {
  url?: string;
  courseId?: string;
  page?: string;
  subchapterId?: string;
}

export const useIds = (): UseIdsResponse => {
  const match1 = useMatch("/course/:courseId");
  const match2 = useMatch("/course/:courseId/:page");
  const match3 = useMatch("/course/:courseId/:page/:subchapterId");
  return {
    url: match3?.pathname ?? match2?.pathname ?? match1?.pathname,
    courseId: match3?.params?.courseId ?? match2?.params?.courseId ?? match1?.params?.courseId,
    page: match3?.params?.page ?? match2?.params?.page,
    subchapterId: match3?.params?.subchapterId,
  };
};

export const useLastLocation = (): string => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, state } = useStateValue();
  const { url = location.pathname, courseId, subchapterId } = useIds();
  const linkingDirectlyToSubchapter = subchapterId != null;

  useEffect(() => {
    if (state.location == null || courseId == null) {
      return;
    }
    const persistedLocation = state.location[courseId];
    if (persistedLocation != null && !linkingDirectlyToSubchapter) {
      navigate(persistedLocation, { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (courseId != null) {
      dispatch({
        type: Actions.UPDATE_LOCATION,
        payload: { courseId, location: url },
      });
    }
  }, [courseId, url, dispatch]);

  return url;
};
