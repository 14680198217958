import React, { useEffect } from "react";

interface Tab {
  id: string;
  title: string;
  body: React.ReactNode;
  hide?: boolean;
}

interface TabsProps {
  tabs: Tab[];
  currentTab: string;
  setCurrentTab: (tabId: string) => void;
}

const Tabs = ({ tabs, currentTab, setCurrentTab }: TabsProps) => {
  const liveTabs = tabs.filter((tab) => !tab.hide);

  useEffect(() => {
    if (!liveTabs.some((tab) => tab.id === currentTab)) {
      setCurrentTab(liveTabs?.[0]?.id ?? "");
    }
  }, [liveTabs, currentTab, setCurrentTab]);

  return (
    <>
      <div className="msr-tabs">
        {liveTabs.map((tab) => (
          <button
            key={tab.id}
            className={tab.id === currentTab ? "selected" : undefined}
            onClick={() => setCurrentTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      {liveTabs.find((tab) => tab.id === currentTab)?.body}
    </>
  );
};

export default Tabs;
