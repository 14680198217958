import { Actions, IAction } from "context/state.context";
import React from "react";

export interface TitleState {
  body: React.ReactNode;
  className?: string;
}

export interface IHeaderState {
  title?: TitleState;
  subtitle?: TitleState;
}

export type IHeaderAction =
  | {
      type: Actions.ADD_HEADER_TITLE | Actions.ADD_HEADER_SUBTITLE;
      payload: TitleState;
    }
  | {
      type: Actions.RESET_HEADER_TITLE | Actions.RESET_HEADER_SUBTITLE;
    };

export const headerReducer = (headerState: IHeaderState | undefined, action: IAction): IHeaderState | undefined => {
  switch (action.type) {
    case Actions.ADD_HEADER_TITLE:
      return {
        ...headerState,
        title: action.payload,
      };
    case Actions.ADD_HEADER_SUBTITLE:
      return {
        ...headerState,
        subtitle: action.payload,
      };
    case Actions.RESET_HEADER_TITLE:
      return {
        ...headerState,
        title: undefined,
      };
    case Actions.RESET_HEADER_SUBTITLE:
      return {
        ...headerState,
        subtitle: undefined,
      };
    default:
      return headerState;
  }
};
