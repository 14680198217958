import React, { Dispatch, Reducer, useContext, useEffect, useReducer } from "react";

import PersistState from "api/persistState";
import { IThemeAction, themeReducer } from "context/theme.context";
import { ILanguageAction, languageReducer } from "context/language.context";
import { ILocationAction, locationReducer } from "context/location.context";
import { ISearchAction, ISearchState, searchReducer } from "context/search.context";
import { IPaymentAction } from "context/payment.context";
import { headerReducer, IHeaderAction, IHeaderState } from "context/header.context";
import { fontReducer, IFontAction, IFontState } from "context/font.context";
import { IUserAction, userReducer } from "context/user.context";
import { courseReducer, ICourseAction } from "context/course.context";
import { dictionaryReducer, IDictionaryAction } from "context/dictionary.context";
import { IUser } from "models/user";
import { ICourse } from "models/course";
import { IDictionaryWord } from "models/dictionaryWord";
import { themes, Themes } from "config/themes/themes.config";
import { Languages } from "config/texts";

export enum Actions {
  UPDATE_THEME = "UPDATE_THEME",
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  UPDATE_LOCATION = "UPDATE_LOCATION",
  ADD_SEARCH_STRING = "ADD_SEARCH_STRING",
  ADD_SEARCH_RESPONSE = "ADD_SEARCH_RESPONSE",
  ADD_PAYMENT = "ADD_PAYMENT",
  ADD_HEADER_TITLE = "ADD_HEADER_TITLE",
  ADD_HEADER_SUBTITLE = "ADD_HEADER_SUBTITLE",
  RESET_HEADER_TITLE = "RESET_HEADER_TITLE",
  RESET_HEADER_SUBTITLE = "RESET_HEADER_SUBTITLE",
  UPDATE_FONT_FAMILY = "UPDATE_FONT_FAMILY",
  UPDATE_FONT_SIZE = "UPDATE_FONT_SIZE",
  UPDATE_LINE_HEIGHT = "UPDATE_LINE_HEIGHT",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_COURSE = "UPDATE_COURSE",
  UPDATE_TEST_SCORES = "UPDATE_TEST_SCORES",
  ADD_DICTIONARY = "ADD_DICTIONARY",
  ADD_DICTIONARY_WORD = "ADD_DICTIONARY_WORD",
}

export interface IState {
  theme: Themes;
  language: Languages;
  location: Record<string, string | null>;
  searchResponse?: ISearchState;
  headerState?: IHeaderState;
  fontState: IFontState;
  userState?: IUser;
  course?: ICourse;
  dictionary: Record<string, IDictionaryWord>;
}

export type IAction =
  | IThemeAction
  | ILanguageAction
  | ILocationAction
  | ISearchAction
  | IPaymentAction
  | IHeaderAction
  | IFontAction
  | IUserAction
  | ICourseAction
  | IDictionaryAction;

interface IContextProps {
  state: IState;
  dispatch: Dispatch<IAction>;
}

const stateReducer = (
  { theme, language, location, searchResponse, headerState, fontState, userState, course, dictionary = {} }: IState,
  action: IAction
): IState => {
  const state = {
    theme: themeReducer(theme, action),
    language: languageReducer(language, action),
    location: locationReducer(location, action),
    searchResponse: searchReducer(searchResponse, action),
    headerState: headerReducer(headerState, action),
    fontState: fontReducer(fontState, action),
    userState: userReducer(userState, action),
    course: courseReducer(course, action),
    dictionary: dictionaryReducer(dictionary, action),
  };
  PersistState.updateState(state, action);
  return state;
};

const StateContext = React.createContext({} as IContextProps);

export const StateProvider = ({ initialState, children }: any) => {
  const [state, dispatch] = useReducer<Reducer<IState, IAction>>(stateReducer, initialState);
  useEffect(() => {
    document.documentElement.className = "";
    document.documentElement.classList.add(themes[state.theme].className);
  }, [state.theme]);

  return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>;
};

export const useStateValue = () => useContext(StateContext);
