import React from "react";
import { Elements } from "@stripe/react-stripe-js";

import { PaymentForm } from "components";
import getStripe from "utils/stripe.util";
import { ITeaser } from "models/course";

const Payments = ({ course }: { course: ITeaser }) => (
  <Elements stripe={getStripe()}>
    <PaymentForm course={course} />
  </Elements>
);

export default Payments;
