import useTexts from "config/texts";
import React from "react";
import { Link } from "react-router-dom";

interface PreNextLinkProps {
  previousHref?: string;
  nextHref?: string;
}

const PreNextLink = ({ previousHref, nextHref }: PreNextLinkProps) => {
  const texts = useTexts();
  return (
    <>
      {previousHref && (
        <Link className="msr-pre-next-link" to={previousHref}>
          {texts.quiz.navigation.previous}
        </Link>
      )}
      {nextHref && (
        <Link className="msr-pre-next-link" to={nextHref}>
          {texts.quiz.navigation.next}
        </Link>
      )}
    </>
  );
};

export default PreNextLink;
