import useTexts from "config/texts";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "utils/scroll.util";

interface Tab {
  id: string;
  title: string;
  hide?: boolean;
}

interface SubchapterBottomNavigationProps {
  previousHref?: string;
  nextHref?: string;
  tabs: Tab[];
  currentTab: string;
  setCurrentTab: (tabId: string) => void;
}

const SubchapterBottomNavigation = ({
  previousHref,
  nextHref,
  tabs,
  currentTab,
  setCurrentTab,
}: SubchapterBottomNavigationProps) => {
  const texts = useTexts();
  const liveTabs = tabs.filter((tab) => !tab.hide && tab.id !== currentTab);

  useEffect(() => {
    scrollToTop();
  }, [currentTab]);

  return (
    <>
      {previousHref && (
        <Link className="msr-pre-next-link" to={previousHref}>
          {texts.quiz.navigation.previous}
        </Link>
      )}
      {liveTabs?.map((tab) => (
        <p
          onClick={() => {
            setCurrentTab(tab.id);
          }}
          className="msr-tab-link"
          key={"link-" + tab.id}
        >
          {tab.title}
        </p>
      ))}
      {nextHref && (
        <Link className="msr-pre-next-link" to={nextHref}>
          {texts.quiz.navigation.next}
        </Link>
      )}
    </>
  );
};

export default SubchapterBottomNavigation;
