import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

interface ButtonProps {
  label: string;
  type?: "submit" | "reset" | "button";
  style?: {};
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
  iconRight?: boolean;
}

const Button = (props: ButtonProps) => (
  <button
    className={classNames("msr-btn", props.className)}
    type={props.type}
    style={props.style}
    onClick={props.onClick}
    disabled={props.disabled}
    title={props.label}
  >
    {props.icon && !props.iconRight && <span>{props.icon}</span>}
    {props.label}
    {props.icon && props.iconRight && <span>{props.icon}</span>}
  </button>
);

interface LinkButtonProps extends LinkProps {
  disabled?: boolean;
  label: string;
}

const LinkButton = (props: LinkButtonProps) => (
  <Link {...props} className={classNames("msr-btn", props.className, { disabled: props.disabled })} title={props.label}>
    {props.label}
  </Link>
);

export const PrimaryLinkButton = ({ className, ...props }: LinkButtonProps) => (
  <LinkButton className={classNames("primary", className)} {...props} />
);

export const SecondaryLinkButton = ({ className, ...props }: LinkButtonProps) => (
  <LinkButton className={classNames("secondary", className)} {...props} />
);

export const PrimaryButton = ({ className, ...props }: ButtonProps) => (
  <Button className={classNames("primary", className)} {...props} />
);

export const SecondaryButton = ({ className, ...props }: ButtonProps) => (
  <Button className={classNames("secondary", className)} {...props} />
);

export const TertiaryButton = ({ className, ...props }: ButtonProps) => (
  <Button className={classNames("tertiary", className)} {...props} />
);
