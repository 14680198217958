import React, { ChangeEvent } from "react";

import { Actions, useStateValue } from "context/state.context";
import { Themes } from "config/themes/themes.config";
import { ToggleSwitch } from "components";
import PersistState from "api/persistState";

const ThemeSwitcher = (): JSX.Element => {
  const { dispatch } = useStateValue();
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: Actions.UPDATE_THEME,
      payload: e.target.checked ? Themes.LIGHT : Themes.DEFAULT,
    });
  };
  return (
    <ToggleSwitch
      className="msr-theme-switcher"
      onChange={onChange}
      checked={PersistState.getTheme() === Themes.LIGHT}
    />
  );
};

export default ThemeSwitcher;
