import { useEffect } from "react";

import { PublicApi } from "api/service";
import { Actions, useStateValue } from "context/state.context";
import { IDictionaryWord } from "models/dictionaryWord";

export const useDictionary = (): IDictionaryWord[] => {
  const { state, dispatch } = useStateValue();

  useEffect(() => {
    PublicApi.fetchDictionary().then((dictionary) => {
      dispatch({ type: Actions.ADD_DICTIONARY, payload: dictionary });
    });
  }, [dispatch]);

  return Object.values(state.dictionary);
};

export const useDictionaryWord = (id: string | undefined): IDictionaryWord | undefined => {
  const { state, dispatch } = useStateValue();
  const persistedWord = state.dictionary[id ?? ""];

  useEffect(() => {
    if (id != null && persistedWord == null) {
      PublicApi.fetchDictionaryWord(id).then((dictionaryWord) => {
        dispatch({ type: Actions.ADD_DICTIONARY_WORD, payload: dictionaryWord });
      });
    }
  }, [id, persistedWord, dispatch]);

  return persistedWord;
};
