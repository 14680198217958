import { FunctionComponent } from "react";

import { PageLayout, PrimaryButton, UserConsents } from "components";
import { LogoutIcon } from "icons";
import useTexts from "config/texts";
import { useStateValue } from "context/state.context";
import Authentication from "utils/auth.util";

const ProfilePage: FunctionComponent = () => {
  const { state } = useStateValue();
  const texts = useTexts();

  return (
    <PageLayout
      main={
        <>
          <h1>{texts.user.profileTitle}</h1>
          <PrimaryButton
            label={texts.menu.logout}
            style={{ width: "auto" }}
            icon={<LogoutIcon style={{ height: "1.5rem", marginRight: "1rem", fill: "#F5F5F5" }} />}
            onClick={() => Authentication.logout()}
          />
          <h3>{texts.user.email}</h3>
          <label>{state?.userState?.email}</label>
          <UserConsents />
        </>
      }
    />
  );
};

export default ProfilePage;
