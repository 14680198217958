import { FunctionComponent } from "react";
import classNames from "classnames";

interface CardProps {
  className?: string;
  id?: string;
}

const Card: FunctionComponent<CardProps> = ({ children, className, id }) => (
  <div id={id} className={classNames("msr-card", className)}>
    {children}
  </div>
);

export default Card;
