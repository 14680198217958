import { Actions, IAction } from "context/state.context";
import { Languages } from "config/texts";

export interface ILanguageAction {
  type: Actions.UPDATE_LANGUAGE;
  payload: Languages;
}

export const languageReducer = (language: Languages, action: IAction): Languages => {
  switch (action.type) {
    case Actions.UPDATE_LANGUAGE:
      return action.payload;
    default:
      return language;
  }
};
