import React, { useEffect, useState } from "react";

import {
  Card,
  MultipleChoiceResult,
  PrimaryButton,
  ProgressBar,
  SecondaryButton,
  SingleMultipleChoice,
} from "components";
import { IMultipleChoiceQuestion, IQuestionAnswer, IQuestionSolution, QuestionType } from "models/quiz";
import { AppAnchors } from "models/paths";
import { PrivateApi } from "api/service";
import { Actions, useStateValue } from "context/state.context";
import { useTheme } from "config/themes/themes.config";
import useTexts from "config/texts";
import { scrollTo } from "utils/scroll.util";

interface MultipleChoiceProps {
  courseId: string;
  documentId: string;
  questions: IMultipleChoiceQuestion[];
  onRetry?: () => Promise<void>;
  saveAnswers?: boolean;
}

export default function MultipleChoice(props: MultipleChoiceProps) {
  const { courseId, documentId, questions = [], onRetry = () => Promise.resolve(), saveAnswers } = props;
  const [showingAnswers, setShowAnswers] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Record<string, IQuestionAnswer>>({});
  const [solutions, setSolutions] = useState<IQuestionSolution[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { dispatch } = useStateValue();
  const texts = useTexts();
  const theme = useTheme();

  useEffect(() => {
    setShowAnswers(false);
    setAnswers({});
    setSolutions([]);
    setCurrentIndex(0);
  }, [questions]);

  useEffect(() => {
    setDisabled(Object.keys(answers).length < currentIndex + 1);
  }, [answers, questions.length, currentIndex]);

  useEffect(() => {
    if (showingAnswers) {
      const results = document.getElementById(AppAnchors.MultipleChoice);
      if (results != null) {
        scrollTo(results);
      }
    }
  }, [showingAnswers]);

  const submitAnswers = () => {
    PrivateApi.submitMultipleChoice(courseId, documentId, answers, saveAnswers).then((a) => {
      setSolutions(a.solutions);
      dispatch({
        type: Actions.UPDATE_TEST_SCORES,
        payload: {
          documentId,
          scores: a.scores,
        },
      });
      setShowAnswers(!showingAnswers);
    });
  };

  const addAnswer = (q: IMultipleChoiceQuestion, id: string) => {
    setAnswers({
      ...answers,
      [q.id]: {
        alternativeId: id,
        parentId: q.parentId,
      },
    });
  };
  if (questions.length === 0) {
    return null;
  }

  const btnText = texts.quiz.btn[QuestionType.MultipleChoice];
  return (
    <Card id={AppAnchors.MultipleChoice} className="msr-quiz">
      <h3>{texts.subchapter.multipleChoiceLabel}</h3>
      {!showingAnswers ? (
        <>
          <ProgressBar
            currentIndex={currentIndex + 1}
            length={questions.length}
            color={theme.className === "msr-theme-light" ? "purple-light" : "purple-dark"}
          />
          {loading ? (
            <p>{texts.quiz.loading}</p>
          ) : (
            <SingleMultipleChoice
              addAnswer={addAnswer}
              answers={answers}
              question={questions[currentIndex]}
              solution={solutions.find((s) => s.questionId === questions[currentIndex].id)!}
            />
          )}
          <div className="msr-quiz-navigation">
            {currentIndex !== 0 ? (
              <SecondaryButton
                label={texts.quiz.navigation.previous}
                onClick={() => {
                  setCurrentIndex(currentIndex - 1);
                }}
              />
            ) : (
              <></>
            )}
            {currentIndex !== questions.length - 1 ? (
              <SecondaryButton
                label={texts.quiz.navigation.next}
                onClick={() => {
                  setCurrentIndex(currentIndex + 1);
                }}
                disabled={disabled}
              />
            ) : (
              <PrimaryButton
                label={showingAnswers ? btnText.hideAnswers : btnText.showAnswers}
                onClick={submitAnswers}
                disabled={disabled}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <MultipleChoiceResult questions={questions} solutions={solutions} answers={answers} />
          <PrimaryButton
            label={btnText.hideAnswers}
            onClick={() => {
              setAnswers({});
              setSolutions([]);
              setLoading(true);
              onRetry().finally(() => setLoading(false));
              setShowAnswers(!showingAnswers);
            }}
            disabled={disabled}
          />
        </>
      )}
    </Card>
  );
}
