import React, { SVGProps } from "react";

const FontPickerIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#66696D" {...props}>
    <path d="M10.384 18L6.08 7.328H4.432L0.144 18H1.664L2.608 15.632H7.92L8.864 18H10.384ZM7.52 14.448H2.992L5.264 8.704L7.52 14.448Z" />
    <path d="M22.98 18L17.6 4.66H15.54L10.18 18H12.08L13.26 15.04H19.9L21.08 18H22.98ZM19.4 13.56H13.74L16.58 6.38L19.4 13.56Z" />
  </svg>
);

export default FontPickerIcon;
