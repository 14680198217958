import React, { SVGProps } from "react";

const SearchIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="48.19 48.1 46 46" fill="#66696D" stroke="#66696D" {...props}>
    <path d="M92.9 91.39l-10.6-10.6c3.03-3.37 4.89-7.81 4.89-12.69 0-10.49-8.51-19-19-19s-19 8.51-19 19 8.51 19 19 19c4.89 0 9.33-1.86 12.69-4.89l10.6 10.6a1.003 1.003 0 001.42-1.42zm-24.71-6.3c-9.39 0-17-7.61-17-17s7.61-17 17-17 17 7.61 17 17-7.61 17-17 17z" />
    <path d="M92.9 91.39l-10.6-10.6c3.03-3.37 4.89-7.81 4.89-12.69 0-10.49-8.51-19-19-19s-19 8.51-19 19 8.51 19 19 19c4.89 0 9.33-1.86 12.69-4.89l10.6 10.6a1.003 1.003 0 001.42-1.42zm-24.71-6.3c-9.39 0-17-7.61-17-17s7.61-17 17-17 17 7.61 17 17-7.61 17-17 17z" />
  </svg>
);

export default SearchIcon;
