import { FunctionComponent } from "react";

import { CourseNavigation, PageLayout, SubchapterBody } from "components";
import { useCourse, useProgress } from "hooks/course.hooks";
import { useLastLocation } from "hooks/location.hooks";

const CoursePage: FunctionComponent = () => {
  const { course, chapter, subchapterTeaser } = useCourse();
  const { progress } = useProgress();
  useLastLocation();

  if (course == null) {
    return null;
  }

  const { id, hasQuiz, chapters = [] } = course;
  return (
    <PageLayout
      sidebar={<CourseNavigation courseId={id} chapters={chapters} showQuizButton={hasQuiz} />}
      main={
        <SubchapterBody course={course} chapter={chapter} subchapterTeaser={subchapterTeaser} progress={progress} />
      }
    />
  );
};

export default CoursePage;
