import React from "react";

import { RadioButton } from "components";
import uniqueId from "utils/unique.util";

interface QuestionProps {
  options?: {
    value: string;
    highlight?: boolean;
    body: React.ReactNode;
  }[];
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}

const RadioButtonGroup = ({ options = [], value, setValue, disabled }: QuestionProps): JSX.Element => {
  const name = uniqueId("radio-button-group");
  return (
    <>
      {options.map((v) => (
        <RadioButton
          key={uniqueId("radio")}
          name={name}
          value={v.value}
          checked={v.value === value}
          setValue={setValue}
          disabled={disabled}
          highlight={v.highlight}
        >
          {v.body}
        </RadioButton>
      ))}
    </>
  );
};

export default RadioButtonGroup;
