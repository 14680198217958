import React, { useState } from "react";

import { SecondaryButton, DangerousTextBody, Card } from "components";
import { ITextQuestion, QuestionType } from "models/quiz";
import { AppAnchors } from "models/paths";
import useTexts from "config/texts";

interface TextQuestionsProps {
  questions: ITextQuestion[];
}

export default function TextQuestions(props: TextQuestionsProps) {
  const { questions = [] } = props;
  const [showAnswers, setShowAnswers] = useState<{ [q: number]: boolean }>({});
  const texts = useTexts();

  if (questions.length === 0) {
    return null;
  }

  const btnText = texts.quiz.btn[QuestionType.TextQuestion];
  return (
    <Card id={AppAnchors.Questions} className="msr-quiz">
      <h3>{texts.subchapter.questionLabel}</h3>
      {questions.map((q, i) => (
        <div className="msr-question" key={`msr-text-question-${i}`}>
          <DangerousTextBody html={q.questionHtml} />
          {showAnswers[i] && (
            <>
              <hr />
              <h4>{texts.quiz.answer}</h4>
              {q.solutionHtml && <DangerousTextBody html={q.solutionHtml} />}
            </>
          )}
          <SecondaryButton
            label={showAnswers[i] ? btnText.hideAnswers : btnText.showAnswers}
            onClick={() => setShowAnswers({ ...showAnswers, [i]: !showAnswers[i] })}
          />
        </div>
      ))}
    </Card>
  );
}
