import { Card, ProgressAccordion, ProgressBar } from "components";
import useTexts from "config/texts";
import { IChapter, ICourseProgress, ISubchapterTeaser } from "models/course";

interface CourseProgressProps {
  progress: ICourseProgress;
}

const CourseProgress = ({ progress }: CourseProgressProps) => {
  const texts = useTexts();
  return (
    <>
      <section className="msr-course-progress__header">
        <h3>{texts.course.progress.title}</h3>
        <p>{texts.course.progress.disclaimer}</p>
        <ProgressBar
          currentIndex={progress.courseProgress.score}
          length={progress.courseProgress.possibleScore}
          color="yellow"
          showPercentage
        />
      </section>
      <Card>
        {progress.chapters.map((chapter: IChapter) => (
          <ProgressAccordion
            key={chapter.id}
            number={chapter.number}
            title={chapter.title}
            progress={chapter.testTaken}
          >
            {chapter.subchapters.map((subchapter: ISubchapterTeaser) => (
              <p className="msr-course-progress__accordion__subchapter" key={subchapter.id}>
                {chapter.number}.{subchapter.number} {subchapter.title}
              </p>
            ))}
            <div className="msr-course-progress__accordion__chapter-test">
              <p>{texts.course.progress.chapterTest.title}</p>
              <p>
                {chapter.testTaken?.possibleScore === 0
                  ? texts.course.progress.chapterTest.notTaken
                  : chapter.testTaken?.score +
                    "/" +
                    chapter.testTaken?.possibleScore +
                    " " +
                    texts.course.progress.chapterTest.score}
              </p>
            </div>
          </ProgressAccordion>
        ))}
      </Card>
    </>
  );
};

export default CourseProgress;
