import React, { InputHTMLAttributes } from "react";
import classNames from "classnames";

import useTexts from "config/texts";

type SearchFieldProps = {
  fullWidth?: boolean;
  large?: boolean;
  themeInverted?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const SearchField = ({
  fullWidth = false,
  large = false,
  themeInverted = false,
  className,
  ...rest
}: SearchFieldProps) => {
  const texts = useTexts();
  return (
    <label className={classNames("msr-search-field", className, { fullWidth, large, themeInverted })}>
      <input {...rest} type="search" placeholder={texts.search.placeholder} />
    </label>
  );
};

export default SearchField;
