import { useEffect, useState } from "react";

import { DangerousTextBody, ProgressBar } from "components";
import { IMultipleChoiceQuestion, IQuestionAnswer, IQuestionSolution } from "models/quiz";
import { CheckMarkIcon, CloseIcon } from "icons";
import useTexts from "config/texts";

interface MultipleChoiceResultProps {
  questions: IMultipleChoiceQuestion[];
  answers: Record<string, IQuestionAnswer>;
  solutions: IQuestionSolution[];
}

enum color {
  "correct" = "#2bc20e",
  "wrong" = "#a00000",
}

export const MultipleChoiceResult = (props: MultipleChoiceResultProps) => {
  const [correctCount, setCorrectCount] = useState<number>();
  const texts = useTexts();

  useEffect(() => {
    let correctAnswersCount = 0;
    props.questions.forEach((q) => {
      const solution = props.solutions.find((s) => s.questionId === q.id);
      if (solution?.correctAlternatives?.some((id) => id === props.answers[q.id]?.alternativeId)) {
        correctAnswersCount += 1;
      }
    });
    setCorrectCount(correctAnswersCount);
  }, [props.answers, props.questions, props.solutions]);

  if (correctCount != null) {
    return (
      <>
        <h2>Ditt resultat</h2>
        <ProgressBar currentIndex={correctCount} length={props.questions.length} color="green" />
        {props.questions.map((q, i) => {
          const solution = props.solutions.find((s) => s.questionId === q.id);
          let feedback: { text: string; correct: boolean } = solution?.correctAlternatives?.some(
            (id) => id === props.answers[q.id]?.alternativeId
          )
            ? { text: texts.quiz.correct, correct: true }
            : { text: texts.quiz.wrong, correct: false };
          const chosenAlternative = q.alternatives.find(
            (alternative) => alternative.id === props.answers[q.id]?.alternativeId
          )?.alternativeHtml;
          return (
            <div key={q.id} className="msr-multiple-choice-feedback">
              <p className="result" style={{ color: color[feedback.correct ? "correct" : "wrong"] }}>
                {feedback.correct ? (
                  <CheckMarkIcon className="check-mark-icon" />
                ) : (
                  <CloseIcon className="close-icon" />
                )}{" "}
                {texts.quiz.question} {i + 1}
              </p>
              <DangerousTextBody html={q.questionHtml} />
              {!feedback.correct ? (
                chosenAlternative ? (
                  <>
                    <p className="answer">{texts.quiz.yourAnswer}</p> <DangerousTextBody html={chosenAlternative} />
                  </>
                ) : null
              ) : null}
              {solution ? (
                <>
                  <p className="answer">{texts.quiz.correctAnswer}</p>{" "}
                  <DangerousTextBody html={solution?.solutionHtml} />
                </>
              ) : null}
              <hr />
            </div>
          );
        })}
      </>
    );
  } else return null;
};
