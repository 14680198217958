import React, { ChangeEvent } from "react";
import { SearchField, SearchResult, SearchResultProps } from "components";

export type SearchResultType = {
  body: React.ReactNode | string;
} & SearchResultProps;

export type SearchResultMap = { [key: string]: SearchResultType[] };

interface SearchContainerProps {
  title: string;
  searchString?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  results: SearchResultType[] | SearchResultMap;
}

const SearchResultList = ({ results = [] }: { results?: SearchResultType[] }) => {
  return (
    <>
      {results.map(({ body, ...rest }) => (
        <SearchResult key={rest.id} {...rest}>
          {body}
        </SearchResult>
      ))}
    </>
  );
};

const SearchResults = ({ results }: { results: SearchResultType[] | SearchResultMap }) => {
  if (Array.isArray(results)) {
    return <SearchResultList results={results} />;
  }
  return (
    <>
      {Object.keys(results).map((group) => (
        <section key={group}>
          {group !== "" && <h2>{group}</h2>}
          <SearchResultList results={results[group]} />
        </section>
      ))}
    </>
  );
};

const SearchContainer = (props: SearchContainerProps): JSX.Element => {
  const { title, searchString, onChange, results = [] } = props;
  return (
    <section className="msr-search-container">
      <h1>{title}</h1>
      <SearchField value={searchString} onChange={onChange} style={{ marginBottom: "1rem" }} large />
      <SearchResults results={results} />
    </section>
  );
};

export default SearchContainer;
