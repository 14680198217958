import React, { useEffect } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import {
  AuthorPage,
  CoursePage,
  DictionaryPage,
  HomePage,
  MultipleChoicePage,
  ProfilePage,
  RepetitionPage,
  SearchPage,
} from "containers";
import { useHeaderTitle } from "hooks/header.hooks";
import { useCourse } from "hooks/course.hooks";
import { useIds } from "hooks/location.hooks";
import { AppLayout, CourseHeader } from "components";
import Paths, { CoursePaths, getCourseRoute } from "models/paths";

const CoursePages = () => {
  const { courseId } = useIds();
  const setHeader = useHeaderTitle("msr-course-header");
  const { course } = useCourse();
  useEffect(() => setHeader(<CourseHeader course={course} />), [setHeader, course]);

  if (course == null || courseId == null || course?.id !== courseId) {
    return <></>;
  }

  return (
    <Routes>
      <Route path={CoursePaths.Course} element={<CoursePage />} />
      <Route path={CoursePaths.CourseFull} element={<CoursePage />} />
      <Route path={CoursePaths.Repetition} element={<RepetitionPage />} />
      <Route path={CoursePaths.RepetitionFull} element={<RepetitionPage />} />
      <Route path={CoursePaths.MultipleChoice} element={<MultipleChoicePage />} />
      <Route path={CoursePaths.MultipleChoiceFull} element={<MultipleChoicePage />} />
      <Route path="*" element={<Navigate to={getCourseRoute(courseId)} />} />;
    </Routes>
  );
};

const App = () => (
  <HashRouter>
    <AppLayout>
      <Routes>
        <Route path={Paths.CourseRoot} element={<CoursePages />} />
        <Route path={Paths.Author} element={<AuthorPage />} />
        <Route path={Paths.Dictionary} element={<DictionaryPage />} />
        <Route path={Paths.Search} element={<SearchPage />} />
        <Route path={Paths.Profile} element={<ProfilePage />} />
        <Route path={Paths.Home} element={<HomePage />} />
      </Routes>
    </AppLayout>
  </HashRouter>
);

export default App;
