import { PrivateApi } from "api/service";
import { Actions, useStateValue } from "context/state.context";

let timer: NodeJS.Timeout;
export const useSearch = () => {
  const { dispatch } = useStateValue();
  return (searchString: string) => {
    clearTimeout(timer);
    dispatch({
      type: Actions.ADD_SEARCH_STRING,
      payload: searchString,
    });
    if (searchString == null || searchString.length === 0) {
      return;
    }
    timer = setTimeout(() => {
      PrivateApi.search(searchString).then((searchResponse) => {
        dispatch({
          type: Actions.ADD_SEARCH_RESPONSE,
          payload: searchResponse,
        });
      });
    }, 500);
  };
};
