import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import ReactModal from "react-modal";
import { useStateValue } from "context/state.context";

ReactModal.setAppElement("#root");

const modalStyles: {
  content: React.CSSProperties;
  overlay: React.CSSProperties;
} = {
  content: {
    inset: "40px",
    height: "fit-content",
    maxHeight: "calc(100vh - 120px)",
    overflowX: "hidden",
    background: "#111113",
    borderColor: "#111113",
    margin: "auto",
    maxWidth: "90vw",
  },
  overlay: {
    background: "rgba(255, 255, 255, 0.25)",
  },
};

interface ModalProps {
  open: boolean;
  close: () => void;
  onlyManualClose?: boolean;
}

const Modal: FunctionComponent<ModalProps> = ({ open, close, onlyManualClose = false, children }) => {
  const { state } = useStateValue();

  useEffect(() => {
    modalStyles.content.background = state.theme === "light" ? "#F5F5F5" : "#111113";
    modalStyles.content.borderColor = state.theme === "light" ? "#F5F5F5" : "#111113";
    modalStyles.overlay.background =
      state.theme === "light" ? "rgba(255, 255, 255, 0.75)" : "rgba(255, 255, 255, 0.25)";
  }, [state]);

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={close}
      style={modalStyles}
      shouldCloseOnOverlayClick={!onlyManualClose}
      shouldCloseOnEsc={!onlyManualClose}
    >
      {!onlyManualClose && (
        <button className="msr-close-modal-btn" onClick={close}>
          X
        </button>
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
