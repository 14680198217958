import React from "react";

import { DangerousTextBody, RadioButtonGroup } from "components";
import { IAlternative } from "models/alternative";

interface QuestionAlternativesProps {
  alternatives?: IAlternative[];
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  correctAlternatives?: string[];
}

const MultipleChoiceAlternatives = ({
  alternatives = [],
  value,
  setValue,
  disabled,
  correctAlternatives,
}: QuestionAlternativesProps): JSX.Element => (
  <RadioButtonGroup
    options={alternatives.map((a) => ({
      value: a.id,
      highlight: disabled && correctAlternatives?.some((id) => id === a.id),
      body: <DangerousTextBody html={a.alternativeHtml} />,
    }))}
    value={value}
    setValue={setValue}
    disabled={disabled}
  />
);

export default MultipleChoiceAlternatives;
