import { ReactNode, useEffect, useState } from "react";
import { Actions, useStateValue } from "context/state.context";

type SetHeader = (body?: ReactNode) => void;

const useTitle = (type: Actions.ADD_HEADER_TITLE | Actions.ADD_HEADER_SUBTITLE, className?: string): SetHeader => {
  const { dispatch } = useStateValue();
  const [body, setBody] = useState<ReactNode>();
  useEffect(() => {
    if (body != null) {
      dispatch({
        type: type,
        payload: {
          body: body,
          className: className,
        },
      });
    }
    return () => {
      dispatch({
        type: type === Actions.ADD_HEADER_TITLE ? Actions.RESET_HEADER_TITLE : Actions.RESET_HEADER_SUBTITLE,
      });
    };
  }, [body, className, dispatch, type]);
  return setBody;
};

export const useHeaderTitle = (className?: string): SetHeader => useTitle(Actions.ADD_HEADER_TITLE, className);
export const useHeaderSubtitle = (className?: string): SetHeader => useTitle(Actions.ADD_HEADER_SUBTITLE, className);
