import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export interface SearchResultProps {
  title: string;
  className?: string;
  id?: string;
  to?: string;
}

const SearchResult: FunctionComponent<SearchResultProps> = ({ title = "", className, to, id, children }) => {
  if (to == null) {
    return (
      <div id={id} className={classNames("msr-search-result", className)}>
        <h3>{title}</h3>
        {children}
      </div>
    );
  }

  return (
    <div id={id} className={classNames("msr-search-result", className)}>
      <h3>
        <Link to={to}>{title}</Link>
      </h3>
      {children}
    </div>
  );
};

export default SearchResult;
