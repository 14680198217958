import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { useStateValue } from "context/state.context";
import { useMediumAndSmallerScreenMatch, usePhoneScreenMatch } from "hooks/media.hooks";
import { SearchBar } from "containers";
import {
  Dropdown,
  FontFamilySettings,
  FontSizeSettings,
  LineHeightSettings,
  MenuItem,
  ThemeSwitcher,
} from "components";
import { FontPickerIcon, MenuIcon } from "icons";
import LogoImage from "images/msr-logo.svg";
import { MenuItemProps } from "models/menuItemProps";
import Paths from "models/paths";

interface HeaderProps {
  menuItems: MenuItemProps[];
}

const Header = ({ menuItems }: HeaderProps) => {
  const { state } = useStateValue();
  const { title, subtitle } = state.headerState || {};
  const isMediumOrSmallerScreen = useMediumAndSmallerScreenMatch();
  const isPhoneScreen = usePhoneScreenMatch();
  const dropdownIcon = isMediumOrSmallerScreen ? (
    <MenuIcon style={{ stroke: "none", height: 40, width: 40 }} />
  ) : (
    <FontPickerIcon style={{ stroke: "none", height: 30, width: 30 }} />
  );

  return (
    <header className="msr-app-header">
      <Link className="msr-header-logo" to={Paths.Home}>
        <img src={LogoImage} alt="My Study Room Logo" />
      </Link>
      {title && <div className={classNames("msr-header-title", title.className)}>{title.body}</div>}
      {subtitle && <div className={classNames("msr-header-subtitle", subtitle.className)}>{subtitle.body}</div>}
      <div className="msr-header-menu">
        {!isMediumOrSmallerScreen && <SearchBar style={{ marginRight: 10 }} />}
        <ThemeSwitcher />
        <Dropdown buttonLabel={dropdownIcon}>
          {isMediumOrSmallerScreen && <SearchBar fullWidth themeInverted />}
          {isPhoneScreen && (
            <>
              <nav style={{ width: 220 }}>
                {menuItems.map((menuItem) => (
                  <MenuItem key={menuItem.label} {...menuItem} themeInverted />
                ))}
              </nav>
              <hr />
            </>
          )}
          <FontSizeSettings />
          <LineHeightSettings />
          <FontFamilySettings />
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;
