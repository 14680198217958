import React, { SVGProps } from "react";

const PenOnPaperIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    className="msr-menu-item-invert"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1.5 1.348 27.152 27.152"
    {...props}
    fill="none"
    stroke="#000"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M13.75 5H5a2.5 2.5 0 0 0-2.5 2.5V25A2.5 2.5 0 0 0 5 27.5h17.5A2.5 2.5 0 0 0 25 25v-8.75" />
    <path d="M23.125 3.125a2.652 2.652 0 1 1 3.75 3.75L15 18.75 10 20l1.25-5L23.125 3.125z" />
  </svg>
);

export default PenOnPaperIcon;
