import { FunctionComponent, useEffect, useState } from "react";

import { Modal } from "components";
import { useStateValue } from "context/state.context";
import { UserConsents } from "components/userConsents";

const UserConsentsModal: FunctionComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { state } = useStateValue();

  useEffect(() => setIsOpen(!state.userState?.hasPromptedConsent), [state.userState]);

  const closeModal = () => setIsOpen(false);
  return (
    <Modal open={modalIsOpen} close={closeModal} onlyManualClose>
      <UserConsents onSave={closeModal} />
    </Modal>
  );
};

export default UserConsentsModal;
