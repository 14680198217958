import Authentication from "utils/auth.util";
import { SearchResponse } from "models/searchResponse";
import { ICourse, ICourseProgress, ISubchapter, ITeasers } from "models/course";
import { IAuthor } from "models/author";
import { IMultipleChoiceQuestion, IQuestionAnswer, ITestTaken, ITextQuestion } from "models/quiz";
import { IPaymentIntent, IPaymentRequest } from "models/payment";
import { IDictionaryWord } from "models/dictionaryWord";
import { IUser, IUserConsents } from "models/user";

const BASE_URL = window.env.REACT_APP_API_URL;

const getHeaders = async (optHeaders: HeadersInit | undefined) => {
  const accessToken = await Authentication.getToken();
  return accessToken == null
    ? optHeaders
    : {
        Authorization: "Bearer " + accessToken,
        ...optHeaders,
      };
};

const responseHandler = async (res: Response) => {
  if (res.status === 200) {
    return res.json();
  }
  return Promise.reject(res.text());
};

const fetch = async (endpoint: string, opt: RequestInit = { method: "get" }) => {
  const options: RequestInit = {
    mode: "cors",
    ...opt,
    headers: {
      accepts: "application/json",
      "Content-Type": "application/json",
      ...opt.headers,
    },
  };

  return window.fetch(BASE_URL + endpoint, options).then((res) => responseHandler(res));
};

const secureFetch = async (endpoint: string, opt: RequestInit = { method: "get" }) => {
  return fetch(endpoint, {
    ...opt,
    headers: await getHeaders(opt.headers),
  }).catch(async (err) => {
    const authenticated = await Authentication.authenticated();
    if (!authenticated) {
      window.location.reload();
    }
    throw err;
  });
};

const secureFetchPost = async (endpoint: string, payload: any = null, opt: RequestInit = { method: "POST" }) => {
  return secureFetch(endpoint, {
    ...opt,
    body: JSON.stringify(payload),
  });
};

export class PublicApi {
  public static fetchAuthor = (id: string): Promise<IAuthor> => fetch("api/author/" + id);
  public static fetchDictionary = (): Promise<IDictionaryWord[]> => fetch("api/dictionary");
  public static fetchDictionaryWord = (id: string): Promise<IDictionaryWord> => fetch("api/dictionary/" + id);
}

export class PrivateApi {
  public static fetchTeasers = (): Promise<ITeasers> => secureFetch("api/course");
  public static fetchCourse = (id: string): Promise<ICourse> => secureFetch("api/course/" + id);
  public static fetchRepetitionCourse = (id: string): Promise<ICourse> => secureFetch("api/course/repetition/" + id);
  public static fetchCourseProgress = (id: string): Promise<ICourseProgress> =>
    secureFetch(`api/course/${id}/progress`);
  public static fetchSubchapter = (courseId: string, subchapterId: string): Promise<ISubchapter> =>
    secureFetch(`api/course/${courseId}/subchapter/${subchapterId}`);
  public static fetchSubchapterMultipleChoice = (
    courseId: string,
    subchapterId: string
  ): Promise<IMultipleChoiceQuestion[]> =>
    secureFetch(`api/course/${courseId}/subchapter/${subchapterId}/multipleChoice`);
  public static fetchTextQuestions = (courseId: string, documentId: string): Promise<ITextQuestion[]> => {
    if (courseId == null || documentId == null) {
      return Promise.reject();
    }
    return secureFetch(`api/quiz/${courseId}/${documentId}/textQuestions`);
  };
  public static fetchMultipleChoice = (courseId: string, documentId: string): Promise<IMultipleChoiceQuestion[]> => {
    if (courseId == null || documentId == null) {
      return Promise.reject();
    }
    return secureFetch(`api/quiz/${courseId}/${documentId}/multipleChoice`);
  };
  public static createPaymentIntent = (request: IPaymentRequest): Promise<IPaymentIntent> =>
    secureFetchPost(`api/payment`, request);
  public static getPaymentStatus = (id: string): Promise<number> => secureFetch(`api/payment/${id}`);
  public static search = (searchString: string): Promise<SearchResponse> => secureFetch("api/search/" + searchString);
  public static submitMultipleChoice = (
    courseId: string,
    documentId: string,
    answers: Record<string, IQuestionAnswer> = {},
    save = false
  ): Promise<ITestTaken> => {
    if (courseId == null || Object.keys(answers).length <= 0) {
      return Promise.reject();
    }
    return secureFetchPost(`api/quiz/${courseId}/${documentId}/multipleChoice?save=${save}`, answers);
  };
  public static fetchUser = (): Promise<IUser> =>
    secureFetch(`api/user`).then((user) =>
      user.hasPromptedConsent
        ? user
        : {
            ...user,
            canTrack: true,
            canStoreData: true,
            canBeContacted: true,
          }
    );
  public static saveUserConsents = (consents: IUserConsents): Promise<IUser> => secureFetchPost(`api/user`, consents);
  public static postActivateCourse = (courseId: string) => secureFetchPost(`api/course/${courseId}/activateTrial`);
}
