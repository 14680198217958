import { useEffect } from "react";

import { AppAnchors } from "models/paths";

export const scrollToTop = () => {
  document.getElementById(AppAnchors.AppContent)?.scrollTo({ behavior: "smooth", top: 0 });
  document.getElementById(AppAnchors.PageContent)?.scrollTo({ behavior: "smooth", top: 0 });
};

export const scrollTo = (el: HTMLElement) => {
  document.getElementById(AppAnchors.AppContent)?.scrollTo({ behavior: "smooth", top: el.offsetTop - 300 });
  document.getElementById(AppAnchors.PageContent)?.scrollTo({ behavior: "smooth", top: el.offsetTop - 300 });
};

export const inView = (ref?: HTMLElement | null, offset = 0): boolean => {
  if (ref == null) {
    return false;
  }
  const top = ref.getBoundingClientRect().top;
  return top + offset >= 0 && top - offset <= window.innerHeight;
};

const useScroll = (el: HTMLElement | null, callback: () => void) => {
  useEffect(() => {
    el?.addEventListener("scroll", callback, { passive: true });
    return () => el?.removeEventListener("scroll", callback);
  }, [el, callback]);
};

export const useScrollEvent = (callback: () => void) => {
  useScroll(document.getElementById(AppAnchors.AppContent), callback);
  useScroll(document.getElementById(AppAnchors.PageContent), callback);
};
