import React, { SVGProps } from "react";

const CheckMarkIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="48.19 48.09 46 46" stroke="#000" {...props}>
    <path d="M85.65 62.37c.14-.15.23-.34.23-.56 0-.45-.37-.82-.82-.82-.23 0-.44.1-.59.26h0l-2.11 2.21v-.01l-1.14 1.2v.01L67.81 78.7l-9.62-11.62a.808.808 0 00-.58-.24c-.45 0-.82.37-.82.82 0 .23.09.43.24.58l10.21 12.21a.808.808 0 001.17-.02h0l13.71-14.36s0 0 0 0l1.17-1.23s0 0 0 0l2.36-2.47h0z" />
    <path d="M85.65 62.37c.14-.15.23-.34.23-.56 0-.45-.37-.82-.82-.82-.23 0-.44.1-.59.26h0l-2.11 2.21v-.01l-1.14 1.2v.01L67.81 78.7l-9.62-11.62a.808.808 0 00-.58-.24c-.45 0-.82.37-.82.82 0 .23.09.43.24.58l10.21 12.21a.808.808 0 001.17-.02h0l13.71-14.36s0 0 0 0l1.17-1.23s0 0 0 0l2.36-2.47h0z" />
  </svg>
);

export default CheckMarkIcon;
