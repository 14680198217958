import { FunctionComponent } from "react";

import { Card } from "components";

const PrivacyInfo: FunctionComponent = () => (
  <Card>
    <h3>MyStudyRoom tar ditt personvern på alvor</h3>
    <p>
      Dette er en selvfølge for oss som jobber med digitale løsninger. Vi skal håndtere dine personopplysninger på en
      trygg og sikker måte. Denne personvernerklæringen gjelder for de som kjøper løsningen til MyStudyRoom og besøkende
      på våre nettsider.
    </p>

    <h4>Personvernerklæring – hva er det?</h4>
    <p>
      En personvernerklæring skal gi informasjon om personopplysninger som samles inn og hvordan personopplysningene
      behandles, samt dine rettighetr knyttet til behandlingen av de opplysningene som hentes inn.
    </p>

    <h5>Hva regnes som personopplysninger?</h5>
    <p>
      Personopplysninger er alle former for data, informasjon, opplysninger og vurderinger som knyttes til deg som
      enkeltperson. Det avgjørende er om opplysningen kan identifisere en konkret person eller om dette ikke kan gjøres.
    </p>
    <p>
      Opplysninger, som ikke kan knyttes til en enkeltperson, kan i visse tilfeller der opplysningen forekommer sammen
      med andre data likevel utgjøre en personopplysning. Eksempler på personopplysningerer navn, telefonnummer og
      e-postadresse.
    </p>

    <h5>Behandlingsansvarlig hos MyStudyRoom</h5>
    <p>
      MyStudyRoom som selskap er behandlingsansvarlig for personopplysninger som samles inn og behandles via vår
      nettside, ref. EUs Personvernforordning (GDPR) artikkel 4 nr. 7. Daglig leder MyStudyRoom er overordnet
      behandlingsansvarlig for behandling av personopplysninger via vår nettside.
    </p>

    <h4>1: Formål og bakgrunn for behandling av personopplysninger</h4>
    <p>
      MyStudyRoom er behandlingsansvarlig og bestemmer formålet med behandlingen av personopplysningene. All behandling
      av personopplysninger skal ha et spesifikt, uttrykkelig angitt formål som er saklig begrunnet i vår virksomhet.
      Dette følger av GDPR artikkel 5 nr. 1 bokstav b, som sier at personopplysninger skal samles inn for «spesifikke,
      uttrykkelig angitte og berettigede formål».
    </p>
    <p>
      Personopplysninger som blir innhentet og samlet inn begrenses følgelig til det som er helt nødvendig for å
      oppfylle formålet med behandlingen.De opplysningene som samles inn til ett konkret formål kan ikke senere brukes
      til et annet formål. Det vil da måtte gjøres en ny vurdering av hvorvidt det finnes et behandlingsgrunnlag for det
      nye formålet.
    </p>
    <p>
      Informasjon vil bli sammenstilt fra MyStudyRoom sine systemer. Formålet med dette er å forbedre våre tjenester
      gjennom læringsanalyse, ulike analyser og statistikker med blant annet bruk av predikasjonsmodeller.
    </p>

    <h4>2: Rettslig grunnlag for behandling av personopplysninger</h4>
    <p>
      For å behandle personopplysninger må det, i tillegg til formålet, foreligge et rettslig grunnlag. Det alminnelige
      kravet til rettslig grunnlag følger av GDPR artikkel 6. For behandling av sensitive personopplysninger kreves
      ytterligere rettslig grunnlag regulert i GDPR artikkel 9. Det rettslige grunnlaget må være oppfylt før
      behandlingen av personopplysninger begynner.
    </p>

    <h5>Nødvendig for å oppfylle en avtale med deg</h5>
    <p>
      Formålet med MyStudyRoom sin behandling av personopplysninger er i tråd med de avtalene vi har inngått med deg om
      vår e-læringsplattform.
    </p>

    <h5>Rettslige forpliktelser</h5>
    <p>
      Vi behandler personopplysninger for å oppfylle våre forpliktelser i henhold til lov, forskrifter eller
      myndighetsbeslutninger. Dette kan være for kontroll og rapportering til offentlig myndigheter.{" "}
    </p>

    <h5>Berettiget interesse</h5>
    <p>
      MyStudyRoom kan behandle personopplysninger dersom det er nødvendig for å ivareta en berettiget interesse som
      veier tyngre enn hensynet til den enkeltes personvern. Den berettigede interessen må være lovlig, definert på
      forhånd, reell og saklig begrunnet i virksomheten. Dette kan være i forbindelse med analyser basert på profilering
      til markedsføringsformål.
    </p>

    <h5>Samtykke</h5>
    <p>
      Dersom ikke annet behandlingsgrunnlag foreligger, vil vår behandling av personopplysninger basere seg på et
      frivillig, uttrykkelig og informert samtykke fra deg.
    </p>
    <p>
      Dersom du har avgitt samtykke til oss, har du rett til å trekke dette tilbake til enhver tid. Trekker du tilbake
      samtykket, vil behandlingen opphøre, og personopplysningene slettes dersom behandlingen utelukkende er basert på
      avgitt samtykke. Samtykket kan trekkes tilbake ved å kontakte oss under Kontakt oss.
    </p>

    <h4>3: Hvilke personopplysninger behandler vi</h4>
    <p>Eneste personopplysningene vi henter inn er epost.</p>

    <h4>4: Hvilken rolle rettigheter har du</h4>
    <p>
      Din rolle er student som er på utkikk etter verktøy for læring innen de fagene vi til enhver tid tilbyr. Enten
      søker du bare informasjon og tester systemet til MyStudyRoom, eller så kjøper du ett av våre produkter som er
      tilgjengelig.{" "}
    </p>
    <p>
      Du har et flertall av rettigheter som skal ivaretas. Det er den registrerte som personopplysningene kan knyttes
      til. Behandlingen av personopplysninger skal utgjøre så lite inngrep som mulig for den enkelte registrerte, ut
      ifra det som er praktisk, teknisk og økonomisk mulig.{" "}
    </p>

    <h5>Rett til informasjon</h5>
    <p>All informasjon du trenger å vite om vår behandling av personopplysninger skal inngå i personvernerklæringen.</p>

    <h5>Rett til innsyn</h5>
    <p>
      Du har rett til å få vite hvilke personopplysninger MyStudyRoom behandler om deg. Dersom du ber om innsyn får du
      kopi av dine personopplysninger, til hvilke formål de er benyttet og om opplysningene er blitt gitt videre og til
      hvem.
    </p>
    <p>
      Dersom det av hensyn til vern av andre personer eller avdekking av brudd på lover og regler, kan MyStudyRoom i
      enkelte tilfeller unnlate å gi deg innsyn dersom dette er nødvendig og vilkårene for det er oppfylt.
    </p>

    <p>Dersom du krever innsyn i personopplysningene, skal det følge en standarisert mal.</p>

    <h5>Rett til korrigering/retting av feil</h5>
    <p>
      Hvis du oppdager at MyStudyRoom har registrert uriktige, utdaterte eller ufullstendige opplysninger om deg, har du
      rett til å få disse korrigert eller oppdatert. Dersom retting av feil gjelder opplysninger som er sendt inn av
      andre, må henvendelser om feil rettes til kilden.
    </p>

    <h5>Rett til sletting</h5>
    <p>
      Du har rett til å kreve at vi sletter personopplysninger om deg. Dersom du ønsker å få slettet dine
      personopplysninger, ber vi deg om å kontakte personvernombud@mysstudyroom.no. Ved henvendelse er det viktig at du
      begrunner hvorfor du ønsker at personopplysningene blir slettet, samt opplyse om hvilke personopplysninger du
      ønsker å få slettet.
    </p>
    <p>
      Lovverket gir MyStudyRoom muligheten til å gjøre unntak fra retten til sletting. Dette kan være tilfeller hvor vi
      behandler personopplysningene for å oppfylle en lovpålagt oppgave, eller for å ivareta viktige samfunnsinteresser
      som arkivering, forskning og statistikk.
    </p>

    <h5>Rett til innsigelse/fremme en protest</h5>
    <p>
      Du har rett til å fremme innsigelse eller protestere mot behandling av dine personopplysninger under visse vilkår,
      dersom behandlingen er basert på berettiget eller allmenn interesse, eller utøvelse av offentlig myndighet.
      Eksempel er behandling som innebærer direkte markedsføring, profilering eller dersom det skjer med tanke på
      vitenskapelig/historisk forskning eller statistikk.
    </p>

    <h5>Rett til begrensning</h5>
    <p>
      I enkelte tilfeller kan du ha rett til å kreve behandlingen av dine personopplysninger blir begrenset. Begrenset
      behandling vil si at personopplysningene fortsatt blir lagret, men at de ikke kan brukes til noe. Du finner mer om
      retten til begrensning hos Datatilsynet.
    </p>

    <h5>Rett til å klage over behandlingen</h5>
    <p>
      Dersom du mener MyStudyRoom ikke har behandlet personopplysningene på en korrekt og lovlig måte, eller dersom du
      mener at vi ikke har klart å oppfylle dine rettigheter, har du mulighet til å klage over behandlingen.
    </p>
    <p>
      Dersom du mener vi behandler dine personopplysninger i strid med regelverket eller dersom du ønsker å benytte deg
      av dine rettigheter, kan du sende henvendelsen til{" "}
      <a href="mailto:contact@mystudyroom.com">contact@mystudyroom.com</a>. Personvernombudet skal ivareta
      personverninteressene til både interessenter, studenter og ansatte knyttet til MyStudyRoom. Dersom vi ikke tar
      klagen din til følge, har du mulighet til å fremme klagen for Datatilsynet. Datatilsynet er ansvarlig for å
      kontrollere at norske virksomheter overholder bestemmelsene i personopplysningsloven og personvernforordningen ved
      behandling av personopplysninger.{" "}
    </p>

    <h4>5: Hvor lenge lagrer vi personopplysningene dine</h4>
    <p>
      MyStudyRoom oppbevarer opplysninger så lenge det er nødvendig for å utføre lovpålagte oppgaver, og i samsvar med
      regelverket. Opplysninger vil som hovedregel slettes når dette behovet faller bort, med mindre vi har en lovpålagt
      plikt til å oppbevare dem etter annet regelverk, for eksempel arkivloven eller bokføringsloven.
    </p>

    <h4>6: Sikkerhet rundt personopplysningene dine</h4>
    <p>
      Dine personopplysninger relatert til MyStudyRoom er sikret med flere tiltak. Vi utfører regelmessig risiko- og
      sårbarhetsanalyser, og tester sikkerheten for å sikre dine personopplysninger.
    </p>
    <p>
      Ansatte som behandler dine personopplysninger er underlagt taushetsplikt, og dette gjelder også ansatte hos
      underleverandører.
    </p>

    <h4>7: Hva registrerer MyStudyRoom når du besøker våre nettsider</h4>
    <p>
      Se eget innlegg der vi beskriver vår bruk av cookies (informasjonskapsler) som er en standard teknologi. Lov om
      elektronisk kommunikasjon av 2013 (ekomloven) § 2-7b regulerer bruk av informasjonskapsler/cookies. En cookie er
      en liten tekstfil som legges i din nettlesers internminne. Ved å sette en cookie kan vi lagre informasjon om
      hvordan du navigerer på nettsiden, gi oss statistikk som vi bruker til å gjøre nettsidene våre bedre, samt å vise
      persontilpasset innhold. Hensikten med dette er å gi deg bedre løsninger og en bedre brukeropplevele.
    </p>
    <p>
      De fleste nettlesere (Google Chrome, Firefox, Internet Explorer, Safari eller Opera) er innstilt på å akseptere
      cookies automatisk. Du kan selv velge å endre innstillingene slik at cookies ikke blir akseptert. Nettsiden vil da
      ikke fungere optimalt. Fremgangsmåte for å endre innstillinger rundt cookies, finner du på dette egne innlegget.{" "}
    </p>

    <h4>8: Oppdateringer</h4>
    <p>
      MyStudyRoom kan revidere eller oppdatere denne personvernerklæringen som følge av at vår behandling av
      personopplysninger endrer seg eller som følge av ny personopplysningslovgivning. Når personvernerklæringen endres,
      vil en oppdatert versjon publiseres på vår nettside.
    </p>

    <h4>9:Kontakt</h4>
    <p>
      Du kan kontakte MyStudyRoom på: <a href="mailto:contact@mystudyroom.com">contact@mystudyroom.com</a>
    </p>
  </Card>
);

export default PrivacyInfo;
