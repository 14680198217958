import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { PrivateApi } from "api/service";
import { PrimaryButton, RadioButtonGroup } from "components";
import { ITheme, useStyles } from "config/themes/themes.config";
import useTexts, { Texts } from "config/texts";
import { getCourseRoute } from "models/paths";
import { ISubscription, ITeaser } from "models/course";
import { useNavigate } from "react-router";
import GA from "utils/google-analytics";

const PaymentStatus = ({ status, errorMessage }: { status: string; errorMessage: string }) => {
  const texts = useTexts();
  switch (status) {
    case "processing":
    case "requires_payment_method":
    case "requires_confirmation":
      return <p>{texts.payment.processing}</p>;
    case "requires_action":
      return <p>{texts.payment.authorizing}</p>;
    case "succeeded":
      return <p>{texts.payment.success}</p>;
    case "error":
      return (
        <p style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}>
          {texts.payment.error} {errorMessage}
        </p>
      );
    default:
      return null;
  }
};

const getBuyDescription = (texts: Texts, subscription: ISubscription) =>
  texts.course.buyDescription
    .replace("%PRICE%", `${subscription.price}`)
    .replace("%DURATION%", `${subscription.durationMonths}`);

const makeStyles = (theme: ITheme) => ({
  iconStyle: "solid" as const,
  hidePostalCode: true,
  style: {
    base: {
      height: "40px",
      iconColor: theme.accentColors.tertiary,
      color: theme.primary.foreground,
      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: theme.primary.foreground,
      },
      "::placeholder": {
        color: theme.primary.foreground,
      },
    },
    invalid: {
      iconColor: theme.accentColors.primary,
      color: theme.accentColors.primary,
    },
  },
});

const PaymentForm = ({ course }: { course: ITeaser }): JSX.Element => {
  const texts = useTexts();
  const navigate = useNavigate();
  const cardOptions = useStyles(makeStyles);
  const [subscription, setSubscription] = useState<ISubscription | undefined>(course?.subscriptions?.[0]);
  const [cardholderName, setCardholderName] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("initial");
  const [errorMessage, setErrorMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    // Abort if form isn't valid
    if (!e.currentTarget.reportValidity()) return;
    setPaymentStatus("processing");

    if (subscription == null) {
      setPaymentStatus("error");
      setErrorMessage(texts.payment.defaultError);
      return;
    }

    // Create a PaymentIntent
    const response = await PrivateApi.createPaymentIntent({
      courseId: course.id,
      subscriptionId: subscription.id,
      amount: subscription.price,
    });
    if (response.statusCode === 500 || response.statusCode === 400) {
      setPaymentStatus("error");
      setErrorMessage(texts.payment.defaultError);
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const { error, paymentIntent } = await stripe!.confirmCardPayment(response.clientSecret, {
      payment_method: {
        card: elements!.getElement(CardElement)!,
        billing_details: { name: cardholderName },
      },
    });
    if (error) {
      setPaymentStatus("error");
      setErrorMessage(error.message ?? texts.payment.defaultError);
    } else if (paymentIntent) {
      PrivateApi.getPaymentStatus(paymentIntent.id)
        .then(() => {
          setPaymentStatus(paymentIntent.status);
          GA.purchaseCourse(course, subscription, paymentIntent.id);
          setTimeout(() => navigate(getCourseRoute(course.id)), 200);
        })
        .catch(() => setPaymentStatus("error"));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
        <RadioButtonGroup
          options={course?.subscriptions?.map((subscription) => ({
            value: subscription.id,
            body: getBuyDescription(texts, subscription),
          }))}
          setValue={(id) => setSubscription(course?.subscriptions?.find((subscription) => subscription.id === id))}
          value={subscription?.id ?? ""}
        />
        <h3>{texts.payment.heading}</h3>
        <input
          className="card-holder-input"
          placeholder={texts.payment.cardHolder}
          type="Text"
          name="cardholderName"
          onChange={(e) => setCardholderName(e.currentTarget.value)}
          required
        />
        <CardElement
          className="card-wrapper"
          options={cardOptions}
          onChange={(e) => {
            if (e.error) {
              setPaymentStatus("error");
              setErrorMessage(e.error.message ?? texts.payment.defaultError);
            } else if (e.complete) {
              setPaymentStatus("");
              setErrorMessage("");
            }
          }}
        />
        <PrimaryButton
          label={texts.payment.submit}
          type="submit"
          disabled={["initial", "succeeded", "error"].includes(paymentStatus) || !cardholderName || !stripe}
        />
      </form>
      <PaymentStatus status={paymentStatus} errorMessage={errorMessage} />
    </>
  );
};

export default PaymentForm;
