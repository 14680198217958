import { useEffect, useState } from "react";

import { watchMediumAndSmallerScreen, watchMediumScreen, watchPhoneScreen, watchSmallScreen } from "utils/media.util";

export const useMediaQueryMatch = (mediaQuery: MediaQueryList) => {
  const [match, setMatch] = useState(mediaQuery.matches);
  const listener = ({ matches }: MediaQueryListEvent) => setMatch(matches);
  useEffect(() => {
    mediaQuery.addEventListener("change", listener);
    return () => mediaQuery.removeEventListener("change", listener);
  }, [mediaQuery]);
  return match;
};

export const usePhoneScreenMatch = () => useMediaQueryMatch(watchPhoneScreen);
export const useSmallScreenMatch = () => useMediaQueryMatch(watchSmallScreen);
export const useMediumScreenMatch = () => useMediaQueryMatch(watchMediumScreen);
export const useMediumAndSmallerScreenMatch = () => useMediaQueryMatch(watchMediumAndSmallerScreen);
