import React from "react";
import classNames from "classnames";

import { getFontClass } from "context/font.context";
import { useFontState } from "hooks/font.hooks";
import { AppAnchors } from "models/paths";

import "./pageLayout.scss";
import "./pageContentLayout.scss";

interface PageLayoutProps {
  sidebar?: React.ReactNode;
  main: React.ReactNode;
  aside?: React.ReactNode;
  className?: string;
}

const PageLayout = ({ className, sidebar, main, aside }: PageLayoutProps): JSX.Element => {
  const fontState = useFontState();
  return (
    <main
      id={AppAnchors.AppContent}
      className={classNames(
        "msr-app-content",
        "msr-page",
        className,
        sidebar == null && aside == null ? "simple" : "grid",
        { [getFontClass(fontState.fontFamily)]: true }
      )}
      style={{
        fontSize: fontState.fontSize,
        lineHeight: fontState.lineHeight,
      }}
    >
      {sidebar && <section className="msr-page-sidebar msr-page-scroll">{sidebar}</section>}
      <div className="msr-page-content msr-page-scroll" id={AppAnchors.PageContent}>
        {aside && <aside className="msr-page-content-aside">{aside}</aside>}
        <section className="msr-page-content-main">{main}</section>
      </div>
    </main>
  );
};

export default PageLayout;
