import { IAlternative } from "models/alternative";
import { ITestScores } from "models/course";

export enum QuestionType {
  TextQuestion = "textQuestion",
  MultipleChoice = "multipleChoice",
}

export interface IMultipleChoiceQuestion {
  id: string;
  parentId: string;
  questionHtml: string;
  alternatives: IAlternative[];
}

export interface ITextQuestion {
  questionHtml: string;
  solutionHtml: string;
}

export interface IQuestionSolution {
  questionId: string;
  correctAlternatives: string[];
  solutionHtml: string;
}

export interface ITestTaken {
  solutions: IQuestionSolution[];
  scores: ITestScores;
}

export interface IQuestionAnswer {
  alternativeId: string;
  parentId: string;
}
