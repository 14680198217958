import { Actions, IAction, IState } from "context/state.context";
import { defaultFontState, IFontState } from "context/font.context";
import { Themes } from "config/themes/themes.config";
import { Languages } from "config/texts";

enum Keys {
  theme = "msr-theme",
  language = "msr-language",
  location = "msr-location",
  font = "msr-font",
}

class PersistState {
  static updateState(state: IState, action: IAction) {
    switch (action.type) {
      case Actions.UPDATE_THEME:
        localStorage.setItem(Keys.theme, state.theme);
        break;
      case Actions.UPDATE_LANGUAGE:
        localStorage.setItem(Keys.language, state.language);
        break;
      case Actions.UPDATE_LOCATION:
        localStorage.setItem(Keys.location, JSON.stringify(state.location));
        break;
      case Actions.UPDATE_FONT_FAMILY:
      case Actions.UPDATE_FONT_SIZE:
      case Actions.UPDATE_LINE_HEIGHT:
        localStorage.setItem(Keys.font, JSON.stringify(state.fontState));
        break;
    }
  }

  static getTheme(): Themes {
    return (localStorage.getItem(Keys.theme) as Themes) || Themes.DEFAULT;
  }

  static getLanguage(): Languages {
    return (localStorage.getItem(Keys.language) as Languages) || Languages.NO;
  }

  static getLocation(): Record<string, string | null> {
    return JSON.parse(localStorage.getItem(Keys.location) || "{}");
  }

  static getFont(): IFontState {
    const persistedState = localStorage.getItem(Keys.font);
    return persistedState != null ? (JSON.parse(persistedState || "{}") as IFontState) : defaultFontState;
  }

  static getState(): IState {
    return {
      theme: PersistState.getTheme(),
      language: PersistState.getLanguage(),
      location: PersistState.getLocation(),
      fontState: PersistState.getFont(),
      dictionary: {},
    };
  }

  static clearPersistedState() {
    localStorage.clear();
  }
}

export default PersistState;
