import React, { ChangeEvent } from "react";
import classNames from "classnames";

interface SwitchButtonProps {
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  checked: boolean;
  style?: {};
  className?: string;
}

const ToggleSwitch = (props: SwitchButtonProps): JSX.Element => (
  <label className={classNames("msr-toggle-switch", props.className)} style={props.style}>
    <input type="checkbox" className="msr-toggle-switch__checkbox" onChange={props.onChange} checked={props.checked} />
    <span className="msr-toggle-switch__dot" />
  </label>
);

export default ToggleSwitch;
