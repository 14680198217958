import classNames from "classnames";
import { CSSProperties } from "react";

interface ProgressBarProps {
  currentIndex: number;
  length: number;
  className?: string;
  showPercentage?: true;
  color?: "green" | "purple-dark" | "purple-light" | "yellow" | undefined;
}

enum color {
  "green" = "#2BC20E",
  "yellow" = "#EAAA00",
  "purple-dark" = "#DB0A5B",
  "purple-light" = "#7D2248",
  "default" = "#39FF14",
}

export const ProgressBar = (props: ProgressBarProps) => {
  var length = props.length !== 0 ? props.length : 1;
  const width: string = ((props.currentIndex / length) * 100).toString() + "%";
  const styles: CSSProperties = { width: width, backgroundColor: color[props.color ?? "default"] };
  return (
    <div className={classNames("msr-progress-bar", props.className)}>
      <div className="msr-current-progress" style={styles}>
        {props.showPercentage ? (
          <p>{Math.round((props.currentIndex / length) * 100 ?? 0) + "%"}</p>
        ) : (
          <p>{props.currentIndex}</p>
        )}
      </div>
      {props.currentIndex === props.length || props.showPercentage ? null : <p>{props.length}</p>}
    </div>
  );
};

export const SmallProgressBar = ({ className, ...props }: ProgressBarProps) => (
  <ProgressBar className={classNames("small", className)} {...props} />
);
