import { FunctionComponent } from "react";

import { Card } from "components";

const CookiesInfo: FunctionComponent = () => (
  <Card>
    <h3>My Studyroom bruker cookies for å kunne gi deg den aller beste brukeropplevelsen</h3>
    <p>
      Når cookies er slått på kan vi by på relevant innhold tilpasset deg og din studiehverdag. Du kan selv velge hvilke
      cookies vi kan bruke, og du kan endre innstillingene når som helst.
    </p>
    <p>
      <b>
        NB! My Studyroom bruker cookies for innlogging så om du sletter eller slår av cookies vil ikke tjenesten fungere
      </b>
    </p>

    <h4>Dette er cookies og slik fungerer det:</h4>
    <p>
      Cookies (på norsk informasjonskapsler) er en standard teknologi. Lov om elektronisk kommunikasjon av 2013
      (ekomloven) § 2-7b regulerer bruk av cookies og vi i MyStudyRoom forholder oss selvsagt til denne loven.
    </p>
    <p>
      Cookies er en tekstfil som lagres på harddisken din når du besøker et nettsted. Statistikken er anonymisert, altså
      ikke koblet til abonnement, eller til deg som person, men relatert til IP adressen på den enheten du benytter når
      du besøker nettstedet. En cookie er ikke et program. Den kan heller ikke inneholde virus. Filene blir slettet
      automatisk etter en viss tidsperiode eller når nettsiden lukkes. Dette kan administreres om ønskelig.
    </p>
    <p>
      MyStudyRoom lagrer ingen personlig, identifiserbar informasjon via cookies, og informasjon om deg kan heller ikke
      spores. Cookies kan være nyttig for oss og ikke minst for deg som bruker våre nettside. Vi kan optimalisere vår
      nettside ut fra den informasjonen som lagres. For eksempel kan vi fjerne innhold som ikke er relevant for
      noen/ingen går inn på. Dette kan blant annet forbedre lastetiden på nettsiden for å ta et eksempel. Dette kan bety
      at nettsidene oppleves mer brukervennlige og relevante for akkurat deg.
    </p>

    <h4>Teknologisk finnes det to typer cookies:</h4>
    <ul>
      <li>fra førstetilbydere som opprettes av nettstedet du besøker</li>
      <li>fra tredjeparter av nettsiden du besøker, som f.eks annonser eller bilder som hentes fra andre nettsider</li>
    </ul>
    <p>
      De fleste nettlesere er innstilt slik at de automatisk aksepterer cookies. Ved å gå inn og hente informasjon,
      eller bruke tjenester på MyStudyRoom, samtykker du i at dette settes i nettleseren din. Hvis du ikke vil tillate
      bruk av cookies, kan du trekke tilbake samtykket ved å endre innstillingene i nettleseren. Husk bare at endringer
      i innstillinger kan medføre at tjenesten på våre nettsider ikke fungerer optimalt.
    </p>

    <h4>Tillat eller avvise cookies:</h4>
    <p>
      Du kan selv bestemme om du ønsker å akseptere eller avslå å samtykke i lagring av cookies. Fro eksempel kommer det
      opp en «aksept av cookies» når du går inn på en nettside. Første steget går på godkjenning her. Merk at valget
      ditt kan medføre at tjenestene på våre nettsider ikke fungerer optimalt.
    </p>
  </Card>
);

export default CookiesInfo;
