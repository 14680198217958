import { Actions, IAction } from "context/state.context";
import { ICourse, ITestScores } from "models/course";

export type ICourseAction =
  | {
      type: Actions.UPDATE_COURSE;
      payload: ICourse;
    }
  | {
      type: Actions.UPDATE_TEST_SCORES;
      payload: {
        documentId: string;
        scores: ITestScores;
      };
    };

const updateTestScores = (
  course: ICourse | undefined,
  documentId: string,
  scores: ITestScores
): ICourse | undefined => {
  if (course == null) {
    return;
  }
  course.chapters = course?.chapters?.map((c) => (c.id === documentId ? { ...c, testTaken: scores } : c));
  return course;
};

export const courseReducer = (course: ICourse | undefined, action: IAction): ICourse | undefined => {
  switch (action.type) {
    case Actions.UPDATE_COURSE:
      return action.payload;
    case Actions.UPDATE_TEST_SCORES:
      return updateTestScores(course, action.payload.documentId, action.payload.scores);
    default:
      return course;
  }
};
