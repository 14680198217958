import { Actions, IAction } from "context/state.context";
import { IUser } from "models/user";
import GA from "utils/google-analytics";

export interface IUserAction {
  type: Actions.UPDATE_USER;
  payload: IUser;
}

export const userReducer = (userState: IUser | undefined, action: IAction): IUser | undefined => {
  switch (action.type) {
    case Actions.UPDATE_USER:
      if (userState?.canTrack !== action.payload?.canTrack) {
        GA.setup(action.payload?.canTrack);
      }
      return {
        ...userState,
        ...action.payload,
      };
    default:
      return userState;
  }
};
