import React from "react";
import { Link } from "react-router-dom";

import { ITeaser } from "models/course";
import { getAuthorRoute } from "models/paths";
import { CheckMarkIcon } from "icons";
import useTexts from "config/texts";

interface TeaserProps {
  course: ITeaser;
  children: React.ReactNode;
}

const CheckMarkText = ({ text }: { text: string }) => (
  <span>
    <CheckMarkIcon /> {text}
  </span>
);

const Teaser = ({ course, children }: TeaserProps) => {
  const texts = useTexts();
  return (
    <div className="msr-teaser">
      <img src={course.imageUrl + "?h=200&w=200&fit=crop"} alt="Course header" />
      <div className="msr-teaser-description">
        <article>
          <span className="msr-teaser-title">
            <h3>{course.title}</h3>
            <Link to={getAuthorRoute(course.authorId)}>{course.author}</Link>
          </span>
          <p>{course.teaserText}</p>
        </article>
        <p>
          {course?.isCompressed && <CheckMarkText text={texts.course.compressed} />}
          {course?.hasQuiz && <CheckMarkText text={texts.course.quiz} />}
          {course?.hasVideo && <CheckMarkText text={texts.course.video} />}
          {course?.adaptiveLearning && <CheckMarkText text={texts.course.adaptiveLearning} />}
        </p>
        <div className="msr-teaser-actions">{children}</div>
      </div>
    </div>
  );
};
export default Teaser;
