import React from "react";

import { MenuItem } from "components";
import { MenuItemProps } from "models/menuItemProps";

interface SidebarProps {
  menuItems: MenuItemProps[];
}

const SidebarMenu = ({ menuItems }: SidebarProps): JSX.Element => (
  <nav className="msr-sidebar-menu">
    {menuItems.map((menuItem) => (
      <MenuItem key={menuItem.label} {...menuItem} iconOnly />
    ))}
  </nav>
);

export default SidebarMenu;
