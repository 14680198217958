import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { useStateValue } from "context/state.context";
import { useRepetitionCourse } from "hooks/course.hooks";
import { CourseNavigation, PageLayout, SubchapterBody } from "components";
import Paths from "models/paths";
import useTexts from "config/texts";

const RepetitionPage: FunctionComponent = () => {
  const { state } = useStateValue();
  const { course, chapter, subchapterTeaser } = useRepetitionCourse();
  const texts = useTexts();

  if (course == null) {
    return null;
  }

  const { id, hasQuiz, chapters = [] } = course;
  return (
    <PageLayout
      sidebar={<CourseNavigation courseId={id} chapters={chapters} showQuizButton={hasQuiz} />}
      main={
        state.userState?.canStoreData ? (
          <SubchapterBody course={course} chapter={chapter} subchapterTeaser={subchapterTeaser} isRepetition />
        ) : (
          <>
            <h2>{texts.course.nav.repetitionTitle}</h2>
            <p>
              {texts.course.cantRepeat} <Link to={Paths.Profile}>{texts.course.linkToProfilePageLabel}</Link>
            </p>
          </>
        )
      }
    />
  );
};

export default RepetitionPage;
