import React, { FunctionComponent, useEffect, useState } from "react";

import { PrivateApi } from "api/service";
import { ITeaser, ITeasers } from "models/course";
import { PageLayout } from "components";
import { TeaserAction, TeaserWithPurchase } from "containers";
import { sanitize } from "utils/sanitize.util";
import LogoImage from "images/home-page.jpg";
import useTexts from "config/texts";

interface TeasersProps {
  action: TeaserAction;
  teasers: ITeaser[];
}

const Teasers = ({ action, teasers = [] }: TeasersProps) =>
  teasers.length === 0 ? (
    <></>
  ) : (
    <>
      {teasers.map((teaser: ITeaser) => (
        <TeaserWithPurchase key={teaser.id} teaser={teaser} action={action} />
      ))}
    </>
  );

const HomePage: FunctionComponent = () => {
  const [teasers, setTeasers] = useState<ITeasers>({} as ITeasers);
  const { activeSubscriptions = [], free = [], trials = [], forSale = [], upcoming = [] } = teasers;
  const texts = useTexts();

  const hasActiveSubscriptions = activeSubscriptions.length > 0;
  const hasFreeCourses = free.length > 0;
  const hasOtherAvailableCourses = trials.length > 0 || forSale.length > 0 || upcoming.length > 0;

  useEffect(() => {
    PrivateApi.fetchTeasers().then((res: ITeasers) => setTeasers(res));
  }, []);

  return (
    <PageLayout
      main={
        <>
          <header className="msr-my-courses-header">
            <h1>
              {texts.effectiveLearning}
              <br />
              {texts.betterResults}
            </h1>
            <img src={LogoImage} alt="Home page header" />
          </header>
          <section style={{ marginBottom: 100 }}>
            <h2>{texts.howToTitle}</h2>
            <p dangerouslySetInnerHTML={{ __html: sanitize(texts.howToBodyHTML) }} />
            <strong>{texts.howToFooter}</strong>
          </section>
          <section className="msr-my-courses-body">
            {hasActiveSubscriptions && (
              <nav>
                <h2>{texts.yourCourses}</h2>
                <Teasers teasers={activeSubscriptions} action={TeaserAction.READ} />
              </nav>
            )}
            {hasFreeCourses && (
              <nav>
                <h2>{texts.freeCourses}</h2>
                <Teasers teasers={free} action={TeaserAction.READ} />
              </nav>
            )}
            {hasOtherAvailableCourses && (
              <nav>
                <h2>{hasActiveSubscriptions || hasFreeCourses ? texts.otherCourses : texts.courses}</h2>
                <Teasers teasers={trials} action={TeaserAction.START_TRIAL} />
                <Teasers teasers={forSale} action={TeaserAction.PURCHASE} />
                <Teasers teasers={upcoming} action={TeaserAction.UPCOMING} />
              </nav>
            )}
          </section>
        </>
      }
    />
  );
};

export default HomePage;
