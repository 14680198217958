import React, { FunctionComponent } from "react";

import { PageLayout, SearchContainer, SearchResultType } from "components";
import { useStateValue } from "context/state.context";
import { useSearch } from "hooks/search.hooks";
import { getAuthorRoute, getCourseRoute, getSubchapterRoute } from "models/paths";
import useTexts from "config/texts";

const SearchPage: FunctionComponent = () => {
  const { state } = useStateValue();
  const texts = useTexts();
  const search = useSearch();

  const searchString = state.searchResponse?.searchString || "";
  const searchResponse = state?.searchResponse?.searchResponse;
  const { authors = [], courses = [], chapters = [], subchapters = [] } = searchResponse || {};

  const response: SearchResultType[] = [];
  subchapters.forEach((subchapter) => {
    response.push({
      body: (
        <p>
          {texts.search.course}: {subchapter.courseTitle}
        </p>
      ),
      title: `${texts.search.chapter}: ${subchapter.chapterTitle} | ${subchapter.title}`,
      id: subchapter.id,
      to: getSubchapterRoute(subchapter.courseId, subchapter.id),
    });
  });
  chapters.forEach((chapter) => {
    response.push({
      body: <p>{`${texts.search.course}: ${chapter.courseTitle}`}</p>,
      title: `${texts.search.chapter}: ${chapter.title}`,
      id: chapter.id,
      to: getSubchapterRoute(chapter.courseId, chapter.firstSubchapter),
    });
  });
  courses.forEach((course) => {
    response.push({
      body: <p>{course.teaser}</p>,
      title: `${texts.search.course}: ${course.title}`,
      id: course.id,
      to: getCourseRoute(course.id),
    });
  });
  authors.forEach((author) => {
    response.push({
      body: <p>{`${texts.email}: ${author.email}`}</p>,
      title: `${texts.search.author}: ${author.firstName} ${author.lastName}`,
      id: author.id,
      to: getAuthorRoute(author.id),
    });
  });

  return (
    <PageLayout
      main={
        <SearchContainer
          title={texts.search.header}
          searchString={searchString}
          onChange={(e) => search(e.target.value)}
          results={response}
        />
      }
    />
  );
};

export default SearchPage;
