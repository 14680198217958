import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { ButtonMenuItemProps, LinkMenuItemProps, MenuItemProps } from "models/menuItemProps";

const ButtonMenuItem = (props: ButtonMenuItemProps): JSX.Element => {
  const { onClick, label, Icon, iconOnly = false } = props;
  return (
    <button title={label} className={classNames("msr-menu-item btn", { iconOnly })} onClick={onClick}>
      <Icon />
      {!iconOnly && label}
    </button>
  );
};

const LinkMenuItem = (props: LinkMenuItemProps): JSX.Element => {
  const location = useLocation();
  const {
    Icon,
    label,
    onClick,
    isActive = () => false,
    iconOnly = false,
    themeInverted = false,
    ...rest
  } = props as LinkMenuItemProps;
  const active = isActive(location.pathname);
  return (
    <Link
      title={label}
      className={classNames("msr-menu-item link", { highlight: active, iconOnly, themeInverted })}
      tabIndex={active ? -1 : undefined}
      onClick={() => {
        if (location.pathname === props.to && onClick != null) {
          onClick();
        }
      }}
      {...rest}
    >
      <Icon />
      {label}
    </Link>
  );
};

const MenuItem = (props: MenuItemProps): JSX.Element => {
  const { hide, ...rest } = props;
  if (hide) {
    return <></>;
  }
  if ((rest as LinkMenuItemProps).to != null) {
    return <LinkMenuItem {...(rest as LinkMenuItemProps)} />;
  }
  if ((rest as ButtonMenuItemProps).onClick != null) {
    return <ButtonMenuItem {...(rest as ButtonMenuItemProps)} />;
  }
  return <></>;
};

export default MenuItem;
