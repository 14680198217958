import { useEffect, useState } from "react";

import { Actions, useStateValue } from "context/state.context";
import { FontFamily, FontSize, IFontState, LineHeight } from "context/font.context";

export const useDispatchFontFamily = () => {
  const { state } = useStateValue();
  const [fontFamily, setLineHeight] = useState<FontFamily>(state.fontState.fontFamily);
  const { dispatch } = useStateValue();
  useEffect(() => {
    if (fontFamily != null && state.fontState.fontFamily !== fontFamily) {
      dispatch({
        type: Actions.UPDATE_FONT_FAMILY,
        payload: fontFamily,
      });
    }
  }, [dispatch, fontFamily, state.fontState.fontFamily]);
  return setLineHeight;
};

export const useDispatchFontSize = () => {
  const { state } = useStateValue();
  const [fontSize, setLineHeight] = useState<FontSize>(state.fontState.fontSize);
  const { dispatch } = useStateValue();
  useEffect(() => {
    if (fontSize != null && state.fontState.fontSize !== fontSize) {
      dispatch({
        type: Actions.UPDATE_FONT_SIZE,
        payload: fontSize,
      });
    }
  }, [dispatch, fontSize, state.fontState.fontSize]);
  return setLineHeight;
};

export const useDispatchLineHeight = () => {
  const { state } = useStateValue();
  const [lineHeight, setLineHeight] = useState<LineHeight>(state.fontState.lineHeight);
  const { dispatch } = useStateValue();
  useEffect(() => {
    if (lineHeight != null && state.fontState.lineHeight !== lineHeight) {
      dispatch({
        type: Actions.UPDATE_LINE_HEIGHT,
        payload: lineHeight,
      });
    }
  }, [dispatch, lineHeight, state.fontState.lineHeight]);
  return setLineHeight;
};

export const useFontState = (): IFontState => {
  const { state } = useStateValue();
  return state.fontState;
};
