import { ISubscription, ITeaser } from "models/course";
import GoogleAnalytics, { PurchaseType } from "utils/google-analytics/ga";
import Authentication from "utils/auth.util";

let ga: GoogleAnalytics | undefined;

class GA {
  private static init(userId: string | undefined, allowTracking: boolean) {
    ga = new GoogleAnalytics({
      targetId: "G-VCVRBWCST5",
      appName: "Learning platform",
      userId,
      debug: !window.env.PROD,
      allowTracking,
    });
  }

  public static setup(allowTracking: boolean) {
    GA.init(Authentication.getUserId(), allowTracking);
  }

  public static checkOutPrices(course: ITeaser): void {
    ga?.view({ id: course.id, name: course.title });
  }

  public static startTrialForCourse(course: ITeaser): void {
    ga?.purchase({
      transaction_id: `${ga?.userId}-trial-${course.id}`,
      id: course.id,
      name: course.title,
      type: PurchaseType.TRIAL,
      duration: course.trialDays,
      price: 0,
    });
  }

  public static purchaseCourse(course: ITeaser, subscription: ISubscription, transactionId: string): void {
    ga?.purchase({
      transaction_id: transactionId,
      id: course.id,
      name: course.title,
      type: PurchaseType.PURCHASE,
      duration: subscription.durationMonths,
      price: subscription.price,
    });
  }
}

export default GA;
