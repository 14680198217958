import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import App from "app";
import { IState, StateProvider } from "context/state.context";
import { UserConsentsModal } from "components";
import Authentication from "utils/auth.util";
import { addVersionToHTML } from "utils/version.util";
import GA from "utils/google-analytics";
import PersistState from "api/persistState";
import { PrivateApi } from "api/service";
import { IUser } from "models/user";

import "styles/main.scss";

declare global {
  interface Window {
    env: {
      REACT_APP_API_URL: string;
      REACT_APP_AUTH_REDIRECT_URL: string;
      REACT_APP_PUBLIC_STRIPE_KEY: string;
      PROD: boolean;
      MSAL_CLIENT_ID: string;
    };
  }
}

addVersionToHTML();
const initialState: IState = PersistState.getState();

export const rootContainer = document.getElementById("root");

const LearningPlatform = (): JSX.Element => {
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    PrivateApi.fetchUser()
      .then(setUser)
      .catch(() => console.error("Failed to fetch user"));
  }, []);

  if (user == null) {
    return <></>;
  }

  GA.setup(user.canTrack);
  return (
    <StateProvider initialState={{ ...initialState, userState: user }}>
      <App />
      <UserConsentsModal />
    </StateProvider>
  );
};

const SecureApp = Authentication.secure(LearningPlatform);
ReactDOM.render(<SecureApp />, rootContainer);
