import { Actions, IAction } from "context/state.context";
import { IDictionaryWord } from "models/dictionaryWord";

export type IDictionaryAction =
  | {
      type: Actions.ADD_DICTIONARY;
      payload: IDictionaryWord[];
    }
  | {
      type: Actions.ADD_DICTIONARY_WORD;
      payload: IDictionaryWord;
    };

const map = (dictionary: IDictionaryWord[]): Record<string, IDictionaryWord> =>
  dictionary.reduce<Record<string, IDictionaryWord>>((acc, word) => {
    acc[word.id] = word;
    return acc;
  }, {});

export const dictionaryReducer = (
  dictionary: Record<string, IDictionaryWord>,
  action: IAction
): Record<string, IDictionaryWord> => {
  switch (action.type) {
    case Actions.ADD_DICTIONARY:
      return map(action.payload);
    case Actions.ADD_DICTIONARY_WORD:
      const dictionaryWord = action.payload;
      dictionary[dictionaryWord.id] = dictionaryWord;
      return dictionary;
    default:
      return dictionary;
  }
};
