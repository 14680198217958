import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Fuse from "fuse.js";

import { useDictionary } from "hooks/dictionary.hooks";
import { IDictionaryWord } from "models/dictionaryWord";
import { DangerousTextBody, PageLayout, SearchContainer, SearchResultMap } from "components";
import { scrollTo } from "utils/scroll.util";
import useTexts from "config/texts";

type DictionaryMap = { [key: string]: IDictionaryWord[] };

const useSearch = (lst: IDictionaryWord[] = [], searchString: string = ""): DictionaryMap => {
  if (searchString == null || searchString === "") {
    const groups: DictionaryMap = {};
    lst.forEach((w) => {
      const group = w.word?.[0];
      if (group != null) {
        if (groups[group] == null) {
          groups[group] = [w];
        } else {
          groups[group].push(w);
        }
      }
    });
    return groups;
  }
  const options = {
    threshold: 0.4,
    keys: ["word", "id", "descriptionHtml"],
  };
  const result = new Fuse(lst, options).search(searchString).map((r) => r.item);
  return { "": result };
};

const DictionaryPage: FunctionComponent = () => {
  const words = useDictionary();
  const [searchString, setSearchString] = useState<string>("");
  const { hash } = useLocation();
  const texts = useTexts();

  useEffect(() => {
    const focused = document.getElementById(hash.substring(1));
    if (focused != null) {
      scrollTo(focused);
      focused.classList.add("active");
    }
  }, [hash, words]);

  const groups = useSearch(words, searchString);
  const results: SearchResultMap = {};
  Object.keys(groups).forEach((group) => {
    results[group] = (groups[group] || []).map((w) => ({
      body: <DangerousTextBody html={w.descriptionHtml} />,
      title: w.word,
      id: w.id,
    }));
  });

  return (
    <PageLayout
      main={
        <SearchContainer
          title={texts.dictionary.header}
          searchString={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          results={results}
        />
      }
    />
  );
};

export default DictionaryPage;
