import React, { useState } from "react";
import { useNavigate } from "react-router";

import { Modal, PrimaryButton, PrimaryLinkButton, Teaser } from "components";
import { Payments } from "containers";
import { ITeaser } from "models/course";
import { getCourseRoute, getMultipleChoiceRoute } from "models/paths";
import useTexts, { Texts } from "config/texts";
import { PrivateApi } from "api/service";
import GA from "utils/google-analytics";

export enum TeaserAction {
  READ,
  PURCHASE,
  UPCOMING,
  START_TRIAL,
}

interface TeaserWithActionsProps {
  teaser: ITeaser;
  action: TeaserAction;
}

const getTrialLabel = (texts: Texts, teaser: ITeaser) => {
  const trialDuration =
    teaser.trialDays <= 2 ? `${teaser.trialDays * 24} ${texts.time.hours}` : `${teaser.trialDays} ${texts.time.days}`;
  return texts.course.trial.replace("%DURATION%", trialDuration);
};

const TeaserWithPurchase = ({ teaser, action }: TeaserWithActionsProps) => {
  const navigate = useNavigate();
  const [showingPrices, showPrices] = useState(false);
  const texts = useTexts();

  const openPrices = () => {
    showPrices(true);
    GA.checkOutPrices(teaser);
  };
  const closePrices = () => showPrices(false);
  const startTrial = () =>
    PrivateApi.postActivateCourse(teaser.id).then(() => {
      GA.startTrialForCourse(teaser);
      navigate(getCourseRoute(teaser.id));
    });

  return (
    <>
      {action === TeaserAction.READ && (
        <Teaser course={teaser}>
          {teaser.hasQuiz && <PrimaryLinkButton to={getMultipleChoiceRoute(teaser.id)} label={texts.course.takeTest} />}
          <PrimaryLinkButton to={getCourseRoute(teaser.id)} label={texts.course.read} />
        </Teaser>
      )}
      {action === TeaserAction.PURCHASE && (
        <Teaser course={teaser}>
          <PrimaryButton onClick={openPrices} label={texts.course.buy} />
        </Teaser>
      )}
      {action === TeaserAction.UPCOMING && (
        <Teaser course={teaser}>
          <PrimaryButton label={texts.course.upcoming} disabled />
        </Teaser>
      )}
      {action === TeaserAction.START_TRIAL && (
        <Teaser course={teaser}>
          <PrimaryButton onClick={() => startTrial()} label={getTrialLabel(texts, teaser)} />
        </Teaser>
      )}
      <Modal open={showingPrices} close={closePrices}>
        <h1>{teaser.title}</h1>
        <Payments course={teaser} />
      </Modal>
    </>
  );
};

export default TeaserWithPurchase;
