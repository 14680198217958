import { useEffect, useState } from "react";

import { PrivateApi } from "api/service";
import { Actions, useStateValue } from "context/state.context";
import { useIds } from "hooks/location.hooks";
import { IChapter, ICourse, ICourseProgress, ISubchapter, ISubchapterTeaser } from "models/course";
import { IMultipleChoiceQuestion } from "models/quiz";
import { getChapter, getSubchapterTeaser } from "utils/course.util";

interface UseCourseResponse {
  course?: ICourse;
  chapter?: IChapter;
  subchapterTeaser?: ISubchapterTeaser;
}

const splitCourse = (course?: ICourse, subchapterId?: string): UseCourseResponse => {
  const chapter = getChapter(course, subchapterId);
  const subchapterTeaser = getSubchapterTeaser(chapter, subchapterId);
  return { course, chapter, subchapterTeaser };
};

export const useCourse = (): UseCourseResponse => {
  const { courseId, subchapterId } = useIds();
  const { state, dispatch } = useStateValue();
  const { course } = state;
  const [currentCourse, setCurrentCourse] = useState(course);

  useEffect(() => {
    if (courseId == null || course?.id === courseId) {
      return;
    }
    PrivateApi.fetchCourse(courseId).then((course) => {
      dispatch({ type: Actions.UPDATE_COURSE, payload: course });
      setCurrentCourse(course);
    });
  }, [courseId, course, dispatch]);

  return splitCourse(currentCourse, subchapterId);
};

interface UseProgressResponse {
  progress?: ICourseProgress;
}

export const useProgress = (): UseProgressResponse => {
  const [progress, setProgress] = useState<ICourseProgress>();
  const { courseId } = useIds();
  const { state } = useStateValue();

  useEffect(() => {
    if (courseId != null) {
      PrivateApi.fetchCourseProgress(courseId).then((res: ICourseProgress) => setProgress(res));
    }
  }, [courseId, state.course]);

  return { progress };
};

export const useRepetitionCourse = (): UseCourseResponse => {
  const [course, setCourse] = useState<ICourse>();
  const { courseId, subchapterId } = useIds();

  useEffect(() => {
    if (courseId != null) {
      PrivateApi.fetchRepetitionCourse(courseId).then((res: ICourse) => setCourse(res));
    }
  }, [courseId]);

  return splitCourse(course, subchapterId);
};

interface UseSubchapterResponse {
  loading: boolean;
  courseId?: string;
  subchapterId?: string;
  subchapter?: ISubchapter;
  updateMultipleChoice?: (questions: IMultipleChoiceQuestion[]) => void;
}

export const useSubchapterFetch = (courseId?: string, subchapterId?: string): UseSubchapterResponse => {
  const [subchapter, setSubchapter] = useState<ISubchapter>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (courseId != null && subchapterId != null) {
      setLoading(true);
      PrivateApi.fetchSubchapter(courseId, subchapterId)
        .then((res: ISubchapter) => setSubchapter(res))
        .finally(() => setLoading(false));
    }
  }, [courseId, subchapterId]);

  const updateMultipleChoice = (questions: IMultipleChoiceQuestion[]) => {
    if (subchapter != null) {
      setSubchapter({ ...subchapter, multipleChoice: questions });
    }
  };

  return { subchapter, loading, updateMultipleChoice };
};
