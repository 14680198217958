import React, { SVGProps } from "react";

const RepeatIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    className="msr-menu-item-invert"
    viewBox="0 0 27 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    stroke="#66696D"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M1.25 2.238v7.5h7.5" />
    <path d="M4.388 15.988a11.25 11.25 0 1 0 2.662-11.7l-5.8 5.45" />
  </svg>
);

export default RepeatIcon;
