import React, { SVGProps } from "react";

const HomeIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="48.19 48.1 46 46.01" {...props}>
    <path d="M92.9 70.39l-7.71-7.71V52.09c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v2.59l-5.29-5.29a.997.997 0 00-.71-.29c-.28 0-.53.11-.71.29l-21 21a1.003 1.003 0 001.42 1.42l4.29-4.29v24.59c0 .55.45 1 1 1h30c.55 0 1-.45 1-1v-24.6l4.29 4.29a1.003 1.003 0 001.71-.71.99.99 0 00-.29-.7zm-13.71-17.3h4v7.59l-4-4v-3.59zm6 38h-28V65.51l14-14 14 14v25.58z" />
    <path d="M92.9 70.39l-7.71-7.71V52.09c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v2.59l-5.29-5.29a.997.997 0 00-.71-.29c-.28 0-.53.11-.71.29l-21 21a1.003 1.003 0 001.42 1.42l4.29-4.29v24.59c0 .55.45 1 1 1h30c.55 0 1-.45 1-1v-24.6l4.29 4.29a1.003 1.003 0 001.71-.71.99.99 0 00-.29-.7zm-13.71-17.3h4v7.59l-4-4v-3.59zm6 38h-28V65.51l14-14 14 14v25.58z" />
  </svg>
);

export default HomeIcon;
