import { Actions, IAction } from "context/state.context";

export interface ILocationAction {
  type: Actions.UPDATE_LOCATION;
  payload: {
    courseId: string;
    location: string;
  };
}

export const LAST_LOCATION = "LAST";
export const COURSE_ID = "COURSE_ID";

export const locationReducer = (
  location: Record<string, string | null>,
  action: IAction
): Record<string, string | null> => {
  switch (action.type) {
    case Actions.UPDATE_LOCATION:
      return {
        ...location,
        [action.payload.courseId]: action.payload.location,
        [LAST_LOCATION]: action.payload.location,
        [COURSE_ID]: action.payload.courseId,
      };
    default:
      return location;
  }
};
