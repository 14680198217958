import { useEffect } from "react";

import { rootContainer } from "index";

export const useAppLayout = (): void => {
  useEffect(() => {
    if (rootContainer != null) {
      rootContainer.classList.add("msr-app-layout");
    }
  }, []);
};
