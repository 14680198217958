import React, { CSSProperties } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useSearch } from "hooks/search.hooks";
import { useStateValue } from "context/state.context";
import Paths, { onPage } from "models/paths";
import { SearchField } from "components";

const useSearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return () => {
    if (!onPage(Paths.Search, location.pathname)) {
      navigate(Paths.Search);
    }
  };
};

interface SearchBarProps {
  fullWidth?: boolean;
  large?: boolean;
  themeInverted?: boolean;
  className?: string;
  style?: CSSProperties;
}

const SearchBar = ({
  fullWidth = false,
  large = false,
  themeInverted = false,
  className,
  style,
}: SearchBarProps): JSX.Element => {
  const search = useSearch();
  const goToSearch = useSearchPage();
  const { state } = useStateValue();
  const searchString = state.searchResponse?.searchString || "";
  return (
    <SearchField
      value={searchString}
      onChange={(e) => search(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          goToSearch();
        }
      }}
      fullWidth={fullWidth}
      large={large}
      themeInverted={themeInverted}
      className={className}
      style={style}
    />
  );
};

export default SearchBar;
