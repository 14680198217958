import { useStateValue } from "context/state.context";

interface IColorTheme {
  background: string;
  foreground: string;
}

export interface ITheme {
  className: string;
  accentColors: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  primary: IColorTheme;
  secondary: IColorTheme;
}

interface ThemesConfig {
  light: ITheme;
  dark: ITheme;
}

export enum Themes {
  DEFAULT = "dark",
  LIGHT = "light",
}

export const themes: ThemesConfig = {
  [Themes.DEFAULT]: {
    className: "msr-theme-dark",
    accentColors: {
      primary: "#DB0A5B",
      secondary: "#EAAA00",
      tertiary: "#FF6A39",
    },
    primary: {
      foreground: "#F5F5F5",
      background: "#111113",
    },
    secondary: {
      foreground: "#66696D",
      background: "#171719",
    },
  },
  [Themes.LIGHT]: {
    className: "msr-theme-light",
    accentColors: {
      primary: "#7D2248",
      secondary: "#C54644",
      tertiary: "#FF6A39",
    },
    primary: {
      foreground: "#232323",
      background: "#F5F5F5",
    },
    secondary: {
      foreground: "#66696D",
      background: "#FFFFFF",
    },
  },
};

export const useTheme = (): ITheme => {
  const { state } = useStateValue();
  const { theme } = state;
  return themes[theme];
};

export const useStyles = <T>(makeStyles: (theme: ITheme) => T): T => {
  const theme = useTheme();
  return makeStyles(theme);
};
